
const formateDateMonth = (number)=>{
    let month = String(number)
    if (month.length < 2){
        month = `0${month}`
        return month
    }else{
        return month
    }
}

const printDate = (date)=>{
    const publishedDate = new Date(date)
    const month = publishedDate.getMonth()+1
    const formatedMonth = formateDateMonth(month)
    const printDate = `${publishedDate.getDate()}.${formatedMonth}.${publishedDate.getFullYear()}`
    return printDate
}

export {printDate}