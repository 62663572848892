import { useState, useEffect } from "react";

const useMultiValueSelect = (data, anyValue, currentValue, setValue)=>{

    const [choiceList, setChoiceList] = useState(currentValue)
    const [inputValue, setInputValue] = useState('')

    useEffect(()=>{

        choiceList.length === 0 &&
        setInputValue (anyValue[0].label)

    }, [data, choiceList, anyValue])

    const getNewDropDownData = (e, dropDownData, anyValue)=>{
        const searchFrase = e.target.value.toLowerCase();
        let newData = [];
        if (searchFrase.length > 0){
            newData = dropDownData.filter((item)=>{
            return item.label.toLowerCase().indexOf(searchFrase) > -1
            });
            if (newData.length > 0){
                return newData
            }
            else{
                return anyValue
              }
            }
          else{
            return dropDownData
          }
    }

    const deleteChoiceItem = (id)=>{
      const newChoiceList = choiceList.filter((item)=>{
        return item.id !== id;
      })
      setChoiceList(newChoiceList)
      setValue(newChoiceList)

  }

    const multiChoice = (id, name, label) =>{
          let newChoiceList = [];
          if (choiceList.length === 0){
            newChoiceList = [{id, name, label}]
            setChoiceList(newChoiceList)
            return newChoiceList
          }
          
          newChoiceList = choiceList.filter((item)=>{
            return item.id !== id;
          })
          if (newChoiceList.length === choiceList.length ){
            newChoiceList = [ ...choiceList, {id, name,label}]
          }
          setChoiceList(newChoiceList)
          return newChoiceList
      }

    return{
        getNewDropDownData, choiceList, setChoiceList, multiChoice, inputValue, setInputValue, deleteChoiceItem
    }
}

export {useMultiValueSelect}