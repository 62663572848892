import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { currentUserSelector } from "../store/selectors";
import DataService from "../service/dataService";


const useSinglePage = (advertId)=>{

    const [advertLoading, setAdvertLoading] = useState(true);
    const [advert, setAdvert] = useState(null)
    const [isAuth, setIsAuth] = useState(false)
    const userData = useSelector(currentUserSelector)
    const [ownAdvert, setOwnAdvert] = useState(false)

    useEffect(()=>{
        const server = new DataService()
        console.log(advertId)
        server.getAdvertData(advertId)
        .then((AdvertData)=>{
            console.log(AdvertData)
            setIsAuth(AdvertData.isAuth)
            setAdvert(AdvertData)
            if(userData.user){
                setOwnAdvert(AdvertData.userId === userData.user.id)
            }
        })
        .then(()=>{
            setAdvertLoading(false)
        })
        .catch((err)=>{
            console.log(err)
        })
    }, [advertId])


    return {
        advert, 
        isAuth, 
        advertLoading, 
        userData,
        ownAdvert
    }
}

export {useSinglePage}