import React, {useState} from "react"
import { useAuthForm } from "../../hooks/useAuthForm";
import { Form, Input } from "reactstrap";
import { Link } from "react-router-dom";


const AuthForm = ()=>{

  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const {tryAuthorization} = useAuthForm()

    return(
        <Form className ="mt-4">
            <Input
                name="email"
                placeholder="Введите email"
                type="email"
                className="mb-3 mt-3"
                onChange={(e)=>{setLoginValue(e.target.value)}}
                value = {loginValue}
            />
            <Input
                name="password"
                placeholder="Введите пароль"
                type="password"
                className="mb-3 mt-3"
                onChange={(e)=>{setPasswordValue(e.target.value)}}
                value = {passwordValue}
            />
            <div className = "d-flex justify-content-center">
                <Link to = '/reset_password'> Забыли пароль? </Link>
            </div>
            <button
            className= "btn btn-secondary center mb-3 mt-3"
            onClick = {(e)=>{
                e.preventDefault()
                tryAuthorization(loginValue, passwordValue)}}
            >
                Вход
            </button>
        </Form>
    )
}

export {AuthForm}