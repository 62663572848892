import React, {useState} from "react";
import { FormGroup, Label, Input , Button , ListGroup } from "reactstrap";
import { phoneNumberValidation } from "../helpers/validators";

const AddContactsForm = (props)=>{
    const [contactType, setContactType] = useState('phone')
    const [contactLabel, setContactLabel] = useState('телефон')
    const [contactValue, setContactValue] = useState('')
    const [contactInputValue, setContactInputValue] = useState('');
    const [contactValueError, setContactValueError] = useState(false)

    const messages = {
        vk: "Введите номер из адреса страницы, например id123456",
        telegram: "Введите имя пользователя telegram, например ivan_petrov",
        phone: "Введите номер телефона начиная с 8 или +7"
    }

    const [inputMessage, setInputMessage] = useState(messages.phone)

    const {addContacts, defaultContactsList} = props
    let [contacts, setContacts] = useState(defaultContactsList)

    const getContactLink = (contactObj) => {
        switch (contactObj.contactType) {
          case "phone":
            return (
                <a href={`tel:${contactObj.contactValue}`}>
                  {contactObj.contactValue}{" "}
                </a>
            );
          case "vk":
            return (
                <a href={`https://vk.com/${contactObj.contactValue}`}>
                  {`https://vk.com/${contactObj.contactValue}`}
                </a>
            );
          case "telegram":
            return (
                <a href={`https://t.me/${contactObj.contactValue}`}>
                  {`https://t.me/${contactObj.contactValue}`}
                </a>
            );
          default:
            return null;
        }
    }

    const onChangeContactType = (e)=>{
        setContactType(e.target.value)
        setInputMessage(messages[e.target.value])
        const label = e.target.selectedOptions[0].label
        setContactLabel(label)
    }

    const onChangeContactValue = (e)=>{
        if(contactType === 'phone'){
            const isError = phoneNumberValidation(e.target.value)
            setContactValueError(isError)
            if(isError){
                setInputMessage("Введите корректный номер телефона")
            } else{
                setInputMessage(messages.phone)
            }
            
        }
        setContactInputValue(e.target.value)
        setContactValue(e.target.value)
    }

    const saveNewContact = ()=>{
        const contactId = Date.now();
        const newContacts = [...contacts , {contactType: contactType , contactValue: contactValue, contactLabel: contactLabel, id: contactId}];
        setContactInputValue('')
        setContacts(newContacts)
        addContacts(newContacts)
    }

    const deleteContact = (id)=>{
        const newContacts = contacts.filter(item=> item.id !== id)
        setContacts(newContacts)
    }

    const inputClass = contactValueError? "is-invalid": ""
    const buttonClass = contactValueError? "disabled": ""
    const messageClass = contactValueError? "input_error_message" : ""

    let contactList;

    if (!defaultContactsList){
        contactList = null
    } else{
        contactList = contacts.map((item)=>{
            return(
                <dl className="row" key= {item.id}>
                    <dt className="col-sm-3">{item.contactLabel}</dt>
                    <dd className="col-sm-6">{getContactLink(item)}</dd>
                    <dd className="col-sm-3">
                        <button
                        className=" btn btn-outline-danger"
                        onClick={()=>{deleteContact(item.id)}}
                        > 
                        Удалить 
                        </button>
                    </dd>
                </dl>
            )
        })
    }

    return(
        <FormGroup className="mt-4" >
                    <Label for="exampleSelect">
                        <h6 > Добавьте контакты и ссылки на социальные сети </h6>
                    </Label>
                    <div className="input-group" >
                        <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        className="select_button"
                        onChange={(e)=>{ onChangeContactType(e)}}
                        >
                        <option value = 'phone'>
                            телефон
                        </option>
                        <option value = 'vk' >
                            vk
                        </option>
                        <option value = 'telegram'>
                        telegram
                        </option>
                        </Input>
                        <Input
                        id="exampleSelect"
                        name="text"
                        type="text"
                        value = {contactInputValue}
                        placeholder="адрес/номер"
                        onChange={(e)=>{ onChangeContactValue(e)}}
                        className= {`input_message ${inputClass}`}
                        >
                        </Input>
                        <Button 
                        className={`${buttonClass} group_button`} 
                        onClick={()=>{saveNewContact()} } >
                            Добавить
                        </Button>
                    </div>
                    <span className={messageClass}> {inputMessage} </span>
                    <ListGroup className="mt-3">
                    {contactList}
                    </ListGroup>
                </FormGroup>
    )
}

export {AddContactsForm}