const searchSelector = (state)=>{
    return state.search.advertsData
}

const authSelector = (state)=>{
    return state.user.isAuth
}

const currentUserSelector = (state)=>{
    return state.user.currentUser
}

const showLoaderSelector = (state)=>{
    return state.loader.showLoader
}

export {searchSelector, authSelector, currentUserSelector, showLoaderSelector}