const isEmail = (string) =>{
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if(!reg.test(string) && string.length > 0 ){
        return false
    } else {
        return true
    }
}

const passwordRepeatValidation = (userPasswordValue, userRepeatPasswordValue)=>{
    if(userPasswordValue !== userRepeatPasswordValue && userRepeatPasswordValue !== ""){
      return true
    } else{
      return false
    }
  }

  const passwordLenghtValidation = (userPasswordValue)=>{
    if (userPasswordValue !== '' && userPasswordValue.length < 6){
      return true
    } else{
      return false
    }
  }

  const userAgeValidation = (age)=>{
    if(age > 14 && age <= 100){
        return false
    }
    else return true
  }

  const phoneNumberValidation = (phone)=>{
    if(phone.length === 0){
      return false
    }
    const phoneStart = phone.slice(0, 2)
    if((phone.length === 12 && phoneStart === "+7") || (phone.length === 11 && phoneStart === "89")){
      return false
    } else return true
  }

export {isEmail, passwordRepeatValidation, passwordLenghtValidation, userAgeValidation, phoneNumberValidation }