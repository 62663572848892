import React, {useState} from "react";
import { Input, ListGroupItem, ListGroup, Label, FormGroup } from 'reactstrap'
import { useSingleValueSelect } from "../../hooks/useSingleValueSelect";

export default function SingleValueSelect (props){

  const {
  propName = '', 
  inputClass = '', 
  label = '',
  dropDownData = [], 
  containerClass = '',
  currentValue,
  anyValue,
  setValue = null
} = props

  const {getNewDropDownData, mainList}  = useSingleValueSelect(dropDownData)
  const [inputFocus, setInputFocus] = useState(false);
  const [inputValue, setInputValue] = useState(currentValue.label || '');
  const [background, setBackground] = useState(false)
  
  const [filteredData, setFilteredData] = useState([])

  let dropdownStyle, dropDownList, bgClass;

  inputFocus? dropdownStyle = '': dropdownStyle = 'hide'
  !background? bgClass = 'dropdown_bg-hide': bgClass = ''

  filteredData.length > 0 ? 
    dropDownList = 
    filteredData.map((item)=>{
      return(
        <ListGroupItem
        className="list_item"
        onClick={()=>{
          setInputValue(item.label);
          setBackground(false)
          setInputFocus(false)
          if(setValue && dropDownData.length > 0){
            setValue({name: item.name, id: item._id, label: item.label,});
          }
        }}
        key = {item._id}
        >
          {item.label}
        </ListGroupItem>
      )
    })
  :
    dropDownList = null

      return (
        <FormGroup className={`mt-4 input_container ${containerClass}`} >
          <div
          className={`dropdownBg ${bgClass}`}
          onClick = {()=>{
            setBackground(false)
            setInputFocus(false);
          }}
          >
        </div>
        <Label htmlFor={propName}>
          <h6 >{label}</h6>
        </Label>
        <Input type="text" id={`${propName}`} className = {`${inputClass} text_input`}
        onFocus={()=> {
          setFilteredData(mainList)
          setInputFocus(true);
          setBackground(true)
          setInputValue('')
          }
        }
  
        value = {inputValue}
        onChange = {(e)=>{
          setInputValue(e.target.value);
          setFilteredData(getNewDropDownData(e, dropDownData, anyValue, mainList))
        }}
        />
        <ListGroup className={ `dropdown ${dropdownStyle}` }
        >
          {dropDownList}
        </ListGroup>
      </FormGroup>
    );
    

  
}

export {SingleValueSelect}
