import React, {useState} from "react";
import { useResetPassword } from "../hooks/useResetPassword";
import { RequestNewPasswordForm } from "./requestNewPasswordForm";

const InputNewPasswordForm = (props)=>{
    const {token} = props;
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')

    const {
        inputNewPasswordForm,
        resetValidationErrors,
        passwordLenghtValidation,
        passwordRepeatValidation,
        passwordLenghtError,
        passwordRepeatError,
        enterNewPassword
    } = useResetPassword(token)

    const newPasswordInputStyle = passwordLenghtError && 'is-invalid'
    const repeatInputStyle = passwordRepeatError && 'is-invalid'
    const submitNewPasswordButtonStyle = (passwordLenghtError || passwordRepeatError || newPassword === '') && 'disabled'

    return(
        <>
        {inputNewPasswordForm ?
        <div>
        <div className='row mt-3'>
            <dt className='col-sm-6'>Введите новый пароль</dt>
                <dd className='col-sm-6'>
                    <input className= {`form-control ${newPasswordInputStyle}`}
                    value = {newPassword}
                    onChange = {(e)=>{
                        passwordLenghtValidation(e.target.value)
                        passwordRepeatValidation(e.target.value, repeatNewPassword)
                        setNewPassword(e.target.value)}}
                    type = "password"
                    />
                {passwordLenghtError&& 
                <span className="input_error_message">
                Пароль должен быть длиннее 5 символов
                </span>}
            </dd>
        </div>
        <div className='row'>
        <dt className='col-sm-6'>Повторите новый пароль</dt>
        <dd className='col-sm-6'>
            <input
            className= {`form-control ${repeatInputStyle}`}
            value = {repeatNewPassword}
            onChange = {(e)=>{
                passwordRepeatValidation(newPassword, e.target.value)
                setRepeatNewPassword(e.target.value)}}
            type = "password"
            />
            {passwordRepeatError&&
            <span class="input_error_message">
                    Введенные пароли не совпадают
            </span>}
        </dd>
    </div>
    <div className = "row mt-4 d-flex justify-content-center">
        <button
        className= {`btn btn-secondary me-2 ${submitNewPasswordButtonStyle}`}
        onClick={()=>{
            enterNewPassword(newPassword)
            }
        }
            >
            Ок
        </button>
        <button
        className= "btn btn-secondary"
        onClick={()=>{
            resetValidationErrors()
            setRepeatNewPassword('')
            setNewPassword('')
            ;}
            }
        >
        Отмена
        </button>
    </div>
    </div> 
        :
            <RequestNewPasswordForm/>}
        </>
    )
}


export {InputNewPasswordForm}