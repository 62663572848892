import React, {useEffect} from "react";
import {Container} from 'reactstrap'
import SearchResults from "../modules/SearchResults/searchResults";
import DataService from "../service/dataService";
import { useDispatch } from "react-redux";
import {signInAction} from '../store/reducers/userReducer'
import { SearchForm } from "../modules/SearchForm/SearchForm";
import { clearAdvertsDataAction } from '../store/reducers/searchReducer';

const SearchPage = ()=>{

    const server = new DataService()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(clearAdvertsDataAction())
        server.auth()
        .then((userData)=>{
            dispatch(signInAction(userData));
            localStorage.setItem('token', userData.token)
        })
        .catch((err)=>{
            console.log(err.message)
        })
    }, [])

    return(
        <>
            <h1 className="text-center form_container main-title mt-5"> Поиск </h1>
            <Container className="container-sm form_container">
                <SearchForm/>
            </Container>
            <Container className="">
                <div className="results d-flex justify-content-center flex-wrap ps-0 ">
                    <SearchResults/>
                </div>
            </Container>
        </>
    )
}

export {SearchPage}