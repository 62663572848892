const Avatar = (props)=>{
    const {src, width, height} = props

    let avatarImage
    if (!src){
        avatarImage = 
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}
            fill="currentColor" 
            className="bi bi-person-circle" 
            viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
    } else{
        avatarImage = 
        <div
        className="avatar_image_container"
        style = {
            {width: `${width}px`,
            height: `${height}px`
        }}
        >
            <img
            className="avatar_image"
            src = {src}
            alt = "authorAvatar"
            />
        </div>
    }

    return(
        avatarImage
    )
}

export {Avatar}