import React from "react";
import {Table} from "reactstrap";
import {useNavigate} from 'react-router-dom';
import { printDate } from "../service/dateFormater";


const FavoriteAdverts = (props)=>{
    const {favoriteAdvertsData} = props
    const navigate = useNavigate()
    let favoriteAdverts;

    if(favoriteAdvertsData){
        favoriteAdverts = favoriteAdvertsData.map((advert, index)=>{
            const advertDate = printDate(advert.publishedDate)
            return(
                <tr
                onClick={()=>{
                    navigate(`/adverts/${advert.id}`)
                }}
                 >
                <th scope="row" key = {advert.id}>
                    {index+1}
                </th>
                    <td>
                        {advert.authorName}
                    </td>
                    <td>
                        {advert.advertType.label}
                    </td>
                    <td>
                        {advert.city.label}
                    </td>
                    <td>
                        {advert.instrument.label}
                    </td>
                    <td>
                        {advertDate}
                    </td>
                </tr>
            )
        })
    } else{
        favoriteAdverts = null
    }

    return(
        <div className="tab-pane fade" id="favorite_adverts" role="tabpanel" aria-labelledby="favor-tab" tabIndex="0">
            <dl className="row" >
            <h3 className="mt-4 mb-4 text-muted " >Избранные объявления</h3>
            <Table responsive>
            <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Имя пользователя
                </th>
                <th>
                    Музыкант/Группа
                </th>
                <th>
                    Город
                </th>
                <th>
                    Инструмент
                </th>
                <th>
                    Дата публикации
                </th>
                </tr>
            </thead>
            <tbody>
                {favoriteAdverts}
            </tbody>
            </Table>
        </dl>
        </div>
    )
}

export {FavoriteAdverts}