import React from "react";
import {Table} from "reactstrap";
import {useNavigate} from 'react-router-dom';
import { printDate } from "../service/dateFormater";


const ProfileAdverts = (props)=>{
    const {userAdverts} = props
    const navigate = useNavigate()

    let myAdvertsList
    if(userAdverts){
        myAdvertsList = userAdverts.map((advertData, index)=>{
            const advertDate = printDate(advertData.publishedDate)
            return (
                <tr
                    key = {advertData._id}
                    className = "profile_advert_item"
                    onClick={()=>{ navigate(`/adverts/${advertData._id}`) }}
                    >
                    <th scope="row">
                        {index+1}
                    </th>
                    <td>
                        {advertData.advertType.label}
                    </td>
                    <td>
                        {advertData.city.label}
                    </td>
                    <td>
                        {advertData.instrument.label}
                    </td>
                    <td>
                        {advertDate}
                    </td>
                </tr>
            )
        })
    }
    else{
        return null
    }
    
    
    return(
    <div className="tab-pane fade show" id="user_adv" role="tabpanel" aria-labelledby="user_adv_tab" tabIndex="0">
        <h3 className="mt-4 mb-4 text-muted " >Мои объявления:</h3>
        <dl>
        <Table responsive>
            <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Музыкант/Группа
                </th>
                <th>
                    Город
                </th>
                <th>
                    Инструмент
                </th>
                <th>
                    Дата публикации
                </th>
                </tr>
            </thead>
        <tbody>
                {myAdvertsList}
        </tbody>
        </Table>
        </dl>
    </div>
    )
}
export {ProfileAdverts}