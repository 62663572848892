import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hoc/userContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataService from "../service/dataService";
import { showAlertAction } from "../store/reducers/alertReducer";

import { calculateAge } from "../helpers/dateFormater";
import { userAgeValidation } from "../helpers/validators";

const useRegistrationForm = (formData)=>{

  const {
    userNameValue,
    userEmailValue,
    userPasswordValue,
    userCity,
    userDateOfBirdth
    } = formData
  
  const [userAge, setUserAge] = useState('')
  const [userAgeError, setUserAgeError] = useState(false)

  const checkUserAge = ()=>{
    setUserAgeError(false)
    if(userDateOfBirdth){
      setUserAge(calculateAge(userDateOfBirdth))
      setUserAgeError(userAgeValidation(calculateAge(userDateOfBirdth)));
    }
  }

  const server = new DataService()
  const dispatch = useDispatch()

  const createNewUser = ()=>{
    const newUserData = {
      name: userNameValue,
      email: userEmailValue,
      password: userPasswordValue,
      city: userCity,
      age: userAge
    }
    server.registration(newUserData)
    .then((res)=>{
      console.log(res.message)
      dispatch(showAlertAction({
        text: res.message,
        type: 'sucsess'
      }))

    })
    .catch((err)=>{
      console.log(err.message)
      dispatch(showAlertAction({
        text: err.message,
        type: 'error'
      }))
    })
  }

  return{
    userAgeError,
    createNewUser, 
    checkUserAge,
    userAge
  }
}

export {useRegistrationForm}