import React, {useState, useEffect} from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataService from '../service/dataService';
import { signInAction } from '../store/reducers/userReducer';
import { showAlertAction } from '../store/reducers/alertReducer';

const RequireAuth = ({children})=>{

    const [authentification, setAuthentification] = useState(true)
    const [isActivated, setIsActivated] = useState(false)
    const isAuth = useSelector((state)=>{
        return state.user.isAuth
    })

    const currentUser = useSelector((state)=>{
        return state.user.currentUser
    })

    const server = new DataService()
    const dispatch = useDispatch()
    const location = useLocation();

    useEffect(()=>{
        server.auth()
        .then((userData)=>{
            console.log(userData)
            dispatch(signInAction(userData));
            localStorage.setItem('token', userData.token)
            setIsActivated(userData.user.isActivated)
        })
        .catch((err)=>{
            console.log(err.message)
        })
        .finally(()=>{
            setAuthentification(false)
        })
    }, [])


    if(isAuth && !authentification && isActivated){
        return children
    }

    else if (!isAuth && !authentification){
        return(<Navigate to = '/login' state = {{from: location}} />)
    }

    else if(isAuth && !isActivated && !authentification){
        const email = currentUser.user.email
        dispatch(showAlertAction({
            text: `Пользователь не активирован. На адрес ${email} было выслано письмо с ссылкой для активации`,
            type: 'error'
        }))
        return(<Navigate to = '/login' state = {{from: location}} />)
    }
}

export {RequireAuth}
