import React from "react";
import { AuthForm } from "../modules/AuthForm/AuthForm";
import { Container } from "reactstrap";
import { NavButton } from "../components/common/navButton/navButton";
import { VkLoginButton } from "../components/VkLoginButton/vkLoginButton";

const LoginPage = () => {
  return (
    <Container className="d-flex align-items-center flex-column mt-5">
      <h1 className="text-center main-title"> Вход </h1>
      <div className="form_container">
        <AuthForm />
        {/* <VkLoginButton /> */}
        <h6 className="text-center"> Еще не зарегистрированы? </h6>
        <NavButton to="/registration" btnClass=" center mb-3 mt-3">
          Регистрация
        </NavButton>
      </div>
    </Container>
  );
};

export { LoginPage };
