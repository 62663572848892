import DataService from "../service/dataService";
import { useDispatch } from "react-redux";
import { logOutAction } from "../store/reducers/userReducer";
import { showAlertAction } from "../store/reducers/alertReducer";

const server = new DataService()

const useDeleteUser = ()=>{
    const dispatch = useDispatch()

    const deleteUser = (id)=>{
        server.deleteUser(id)
        .then((res)=>{
            dispatch(logOutAction())
            dispatch(showAlertAction({
                text: res.message,
                type: 'sucsess'
            }))
        })
        .catch((err)=>{
            dispatch(showAlertAction({
                text: err.message,
                type: 'error'
            }))
        })
    }

    return {
        deleteUser
    }
}

export {useDeleteUser}