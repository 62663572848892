import React from "react";
import { AdvertContactItem } from "./advertContactItem";
import { ReactComponent as PhoneIcon } from "../assets/icons/phone-solid.svg";
import { ReactComponent as VkIcon } from "../assets/icons/vk.svg";
import { ReactComponent as TelegramIcon } from "../assets/icons/telegram.svg";

const AdvertContacts = (props) => {
  const { contacts } = props;

  const getContactWrapper = (contactObj) => {
    switch (contactObj.contactType) {
      case "phone":
        return (
          <AdvertContactItem
            icon={<PhoneIcon />}
            id={contactObj.id}
            key={contactObj.id}
          >
            <a href={`tel:${contactObj.contactValue}`}>
              {contactObj.contactValue}{" "}
            </a>
          </AdvertContactItem>
        );
      case "vk":
        return (
          <AdvertContactItem
            id={contactObj.id}
            key={contactObj.id}
            icon={<VkIcon />}
          >
            <a href={`https://vk.com/${contactObj.contactValue}`}>
              {contactObj.contactValue}{" "}
            </a>
          </AdvertContactItem>
        );
      case "telegram":
        return (
          <AdvertContactItem
            key={contactObj.id}
            id={contactObj.id}
            icon={<TelegramIcon />}
          >
            <a href={`https://t.me/${contactObj.contactValue}`}>
              {contactObj.contactValue}{" "}
            </a>
          </AdvertContactItem>
        );
      default:
        return null;
    }
  };
  let contactList;

  if (contacts.length > 0) {
    contactList = contacts.map((c) => {
      return getContactWrapper(c);
    });
  }
  return (
    <>
      <dt> Контакты: </dt>
      <div className="contact_list">{contactList}</div>
    </>
  );
};

export { AdvertContacts };
