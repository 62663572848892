import React from "react";
import { Container } from "reactstrap";
import { ResetPasswordForm } from "../modules/ResetPasswordForm/ResetPasswordForm";

const ResetPasswordPage = ()=>{
    return (
    <Container className="d-flex align-items-center flex-column mt-5">
        <h1 className="text-center main-title"> Восстановление пароля </h1>
            <div className="form_container pt-4">
                <ResetPasswordForm/>
            </div>
    </Container>
    )
}

export {ResetPasswordPage}