const defaultState = {
    currentUser: {},
    isAuth: false
}

const SIGN_IN = 'SIGN_IN';
const LOG_OUT = 'LOG_OUT';
const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
const CHANGE_USER_NAME = 'CHANGE_USER_NAME';
const SET_AVATAR = "SET_AVATAR";
const DELETE_AVATAR = "DELETE_AVATAR";

export default function userReducer (state = defaultState, action){
    switch(action.type){
        case SIGN_IN:
            return{
                currentUser: action.payload,
                isAuth: true
            }
        case LOG_OUT:
            return{
                currentUser: defaultState.currentUser,
                isAuth: false
            }
        case CHANGE_USER_NAME:
            return{
                ...state,
                currentUser: {...state.currentUser, user: {...state.currentUser.user, name: action.payload}} 
            }

        case CHANGE_USER_EMAIL:
            return{
                ...state, 
                currentUser: {...state.currentUser, user: {...state.currentUser.user, email: action.payload}} 
            }

        case SET_AVATAR:
            return{
                ...state,
                currentUser: {
                    ...state.currentUser, user: {
                        ...state.currentUser.user, avatar: action.payload
                    }
                }
            }

        case DELETE_AVATAR:
            return{
                ...state,
                currentUser: {
                    ...state.currentUser, user: {
                        ...state.currentUser.user, avatar: null
                    }
                }
            }

        default:
            return state
    }
}

export const signInAction = (payload)=>({type: SIGN_IN, payload})
export const logOutAction = ()=>({type: LOG_OUT})
export const changeUserNameAction = (payload)=>({type: CHANGE_USER_NAME, payload})
export const changeUserEmailAction = (payload)=>({type: CHANGE_USER_EMAIL, payload})
export const deleteUserAvatarAction = ()=>({type: DELETE_AVATAR})
export const setUserAvatarAction = (payload)=>({type: SET_AVATAR, payload})