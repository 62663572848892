import { useEffect, useState } from "react";
import { passwordRepeatValidation, passwordLenghtValidation } from "../helpers/validators";

const useNewPassword = (inputPassword, repeatPassword)=>{

    const [passwordRepeatError, setPasswordRepeatError] = useState(false);
    const [passwordLenghtError, setPasswordLenghtError] = useState(false);

    useEffect(()=>{
        setPasswordLenghtError(passwordLenghtValidation(inputPassword));
        setPasswordRepeatError(passwordRepeatValidation(inputPassword, repeatPassword));
      }, [inputPassword, repeatPassword])

    return {passwordRepeatError, passwordLenghtError}
}

export {useNewPassword}