import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userReducer';
import alertReducer from './reducers/alertReducer';
import {searchReducer} from './reducers/searchReducer'
import { loaderReducer } from './reducers/loaderReducer';

const store = configureStore({
    reducer:{
        user: userReducer,
        alert: alertReducer,
        search: searchReducer,
        loader: loaderReducer
    }
});

export {store}

