import {useState, useEffect} from 'react';
import { changeUserEmailAction } from '../store/reducers/userReducer';
import { useDispatch } from 'react-redux';
import DataService from '../service/dataService';
import { showAlertAction } from '../store/reducers/alertReducer';
import { isEmail } from '../helpers/validators';

const useProfileEmail = (initialValue)=>{
    const [currentEmail, setCurrentEmail] = useState(initialValue)
    const server = new DataService()
    const dispatch = useDispatch()
    console.log(currentEmail)

    useEffect(()=>{
        setCurrentEmail(initialValue)
    },[])

    const updateEmail = (email, password) =>{ 
        if (isEmail(email)){
            server.confirmPassword({email: currentEmail, password})
            .then((res)=>{
                server.changeUserEmail({oldEmail: currentEmail, newEmail: email})
            })
            .then((res)=>{
                dispatch(changeUserEmailAction(email)) // изменение email в redux
                dispatch(showAlertAction({ // вызов уведомления о смене email
                    type: "sucsess",
                    text: `email изменен. ссылка для активации нового email выслана на ${email}`
                }))
            }).catch((err)=>{
                dispatch(showAlertAction({ // вызов уведомления о смене email
                    type: "error",
                    text: err.message
                }))
            })

        } else{
            dispatch(showAlertAction({ // вызов ошибки при невалидном email
                type: "error",
                text: "некорректный email"
            })) 
        }
    }

    return {
        updateEmail
    }
}

export {useProfileEmail}