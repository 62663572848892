import {useState, useContext} from 'react';
import { useDispatch } from 'react-redux';
import DataService from '../service/dataService';
import { showAlertAction } from '../store/reducers/alertReducer';
import { UserContext } from '../hoc/userContext';

const server = new DataService()


const useProfilePassword = ()=>{
    const [showNewPasswordForm, setShowNewPasswordForm] = useState(false)
    const [showCurrentPasswordForm, setShowCurrentPasswordForm] = useState(true)
    const [currentPasswordError, setCurrentPasswordError] = useState(false)
    const {userData} = useContext(UserContext)
    const {id} = userData
    
    const dispatch = useDispatch()

    const resetValidationErrors = ()=>{
        setCurrentPasswordError(false)
    }

    const enterCurrentPassword = (password)=>{
        server.confirmPassword({id, password})
        .then(()=>{
            setShowCurrentPasswordForm(false)
            setShowNewPasswordForm(true)
        })
        .catch((err)=>{
            setCurrentPasswordError(true)
            dispatch(showAlertAction({ 
                type: "error",
                text: err.message
            }))
        })
        
    }

    const enterNewPassword = (password)=>{
        server.changeUserPassword({id, password})
        .then((res)=>{
            setShowCurrentPasswordForm(true)
            setShowNewPasswordForm(false)
            dispatch(showAlertAction({ 
                type: "sucsess",
                text: res.message
            }))
        })
        .catch((err)=>{
            dispatch(showAlertAction({ 
                type: "error",
                text: err.message
            }))
        })
    }

    return {
        currentPasswordError,
        resetValidationErrors,
        enterNewPassword,
        enterCurrentPassword,
        showNewPasswordForm,
        showCurrentPasswordForm,
        setShowNewPasswordForm,
        setShowCurrentPasswordForm
    }
}

export {useProfilePassword}