import React from 'react';
import { Header } from '../header/header';
import {Outlet} from 'react-router-dom';
import {Container} from 'reactstrap';
import {Footer} from '../footer/footer';

const Layout = () =>{
    return(
        <>
            <Header/>
                <Container className="container-sm pt-5">
                    <Outlet/>
                </Container>
            <Footer/>
        </>
    )
}

export {Layout}