import React from "react";
import { useParams } from "react-router-dom";
import {NavButton} from '../components/common/navButton/navButton';
import {printDate} from '../service/dateFormater';
import { useSinglePage } from "../hooks/useSinglePage";
import { Avatar } from "../components/avatar";
import { FavoriteButton } from "../components/favoriteButton/favotiteButton";
import { Loader } from "../components/common/loader/loader";
import { AdvertContacts } from "../components/advertContacts";


const SinglePage = ()=>{

    const {id} = useParams();
    const {advert, isAuth, advertLoading, ownAdvert} = useSinglePage(id)
    let contacts, styles;
    console.log(advert)

    styles = !advertLoading && advert.style.length > 0 ?
        advert.style.map((style)=>{
            return(
                <span key = {String(style.id)} className="badge rounded-pill  music-style_badge">
                    {style.name}
                </span>
            )
        })
        :
        null
        
    if (!isAuth){
        contacts = <>
            <span> Только зарегистрированные пользователи могут просматривать контакты в объявлениях </span>
            <br/>
            <div style = {{maxWidth: "700px"}} className = "d-flex flex-wrap justify-content-center">
            <NavButton
            to = '/registration'
            btnClass='btn btn-secondary me-3 mt-3'>
                Регистрация
            </NavButton>
            <NavButton
            btnClass='btn btn-secondary me-3 mt-3'
            to = '/login'>
                Вход
            </NavButton>
            </div>
        </>

    }else if(!advertLoading && advert.contacts !== null){
        contacts =
        <AdvertContacts
        contacts = {advert.contacts}
        />
    }

    const editButton = ownAdvert?
    <div style = {{maxWidth : "700px"}} className="d-flex justify-content-center">
        <NavButton
            btnClass='btn btn-secondary me-3 mt-3'
            to = {`edit`}
            >
            Редактировать
        </NavButton>
    </div> 
    : null

        let favoriteButton;
        if(isAuth){
        favoriteButton = 
        <FavoriteButton
        advertData = {advert}
        fontSize = '2.5em'
        />
        }  else{
        favoriteButton = null
        }

    if(!advertLoading){
        return(
            <>
            <div className="result_item mt-5">
                <div className="d-flex flex-wrap">
                <h1>{advert.advertType.label} </h1>
                <div
                className="ms-4">
                    {favoriteButton}
                </div> 
                </div>
                <div className="mt-3 mb-3">
                    {advert.authorAvatar?
                    <Avatar
                        src = {advert.authorAvatar}
                        width = "150"
                        height = "150"
                    />
                    :
                    null
                    }
                </div>
                {
                advert.advertType.name === "fromMusicians"? 
                <>
                    <dl className="row">
                        <dt className="col-sm-3 prop_name">Имя</dt>
                        <dd className="col-sm-9 prop_value">{advert.authorName}</dd>
                    </dl>
                <dl className="row">
                    <dt className="col-sm-3 prop_name">Возраст</dt>
                    <dd className="col-sm-9 prop_value">{advert.authorAge}</dd>
                </dl>
                </>:
                    null
                }
                <dl className="row">
                    <dt className="col-sm-3 prop_name">Город</dt>
                    <dd className="col-sm-9 prop_value">{advert.city.label}</dd>
                </dl>
                <dl className="row">
                    <dt className="col-sm-3 prop_name">Инструмент</dt>
                    <dd className="col-sm-9 prop_value">{advert.instrument.label}</dd>
                </dl>
                <dl className="row">
                    <dt className="col-sm-3 prop_name">Стиль</dt>
                    <dd className="col-sm-9 prop_value">
                        {styles}
                    </dd>
                </dl>
                <dl className="row">
                    <dt className="col-sm-3 prop_name">О себе</dt>
                    <dd className="col-sm-9 prop_value">{advert.advertDescription}</dd>
                </dl>
                <dl className="row">
                    <dt className="col-sm-3 prop_name">Дата публикации</dt>
                    <dd className="col-sm-9 prop_value">{ printDate(advert.publishedDate)}</dd>
                </dl>
            </div>
            {contacts}
            {editButton}
        </>
        )
    }
    else{
        return (

            <div className="loader_container">
                <Loader/>
            </div>
        )
    }
}

export {SinglePage}