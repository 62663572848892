import React from "react"

const AdvertContactItem = (props) => {
    const {children, icon} = props;
  
    return (
      <div className="contact_item">
        <div className="contact_icon_container">{icon}</div>
        
        <div className="contact_value">{children}</div>
      </div>
    );
  };
  
  export { AdvertContactItem };
  