import React from "react"
import { ModalWindow } from "./common/modalWindow"
import { useDeleteUser } from "../hooks/useDeleteUser"

const DeleteUser = (props)=>{
    const {userId} = props
    const {deleteUser} = useDeleteUser()
    
    return(
        <div style = {{ maxWidth: "300px"}}>
            <ModalWindow
            modalMessage = 'Вы точно хотите удалить свою учетную запись?'
            modalTitle = 'Удалить учетную запись'
            buttonText = 'Удалить учетную запись'
            confirm = {deleteUser}
            id = {userId}
            />
        </div>
    )
}


export {DeleteUser}