import React, {useState} from 'react';
import { useProfilePassword } from '../hooks/useProfilePassword';
import { useNewPassword } from '../hooks/useNewPassword';
import { useNavigate } from 'react-router-dom';


const ProfilePassword = ()=>{
    const navigate = useNavigate()
    const [editMode, setEditMode] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')
    const {passwordRepeatError, passwordLenghtError} = useNewPassword(newPassword, repeatNewPassword)

    const{
        currentPasswordError,
        resetValidationErrors,
        enterNewPassword,
        enterCurrentPassword,
        showNewPasswordForm,
        showCurrentPasswordForm,
    } = useProfilePassword()

    const clearPasswordFields = ()=>{
        setCurrentPassword('')
        setRepeatNewPassword('')
        setNewPassword('')
    }

    const onExitEditMode = ()=>{
        clearPasswordFields()
        setEditMode(false)
    }

    const currentPasswordInputStyle = currentPasswordError && 'is-invalid'
    const newPasswordInputStyle = passwordLenghtError && 'is-invalid'
    const repeatInputStyle = passwordRepeatError && 'is-invalid'
    const submitNewPasswordButtonStyle = (passwordLenghtError || passwordRepeatError || newPassword === '') && 'disabled'

    const currentPasswordForm = showCurrentPasswordForm &&
    <><dt className="col-sm-2 profile_info_field"> Введите текущий пароль </dt>
        <div className="col-sm-3">
        <input className= {`form-control ${currentPasswordInputStyle}`}
        value = {currentPassword}
        onChange = {(e)=>{
            resetValidationErrors()
            setCurrentPassword(e.target.value)}}
        type = "password"
        />
        </div>
        <dd className="col-sm-6">
        <button
            className= "btn btn-outline-dark me-2"
            onClick={()=>{
                enterCurrentPassword(currentPassword)
                setCurrentPassword('')
                }
            }
            >
            Ок
        </button>
        <button
            className= "btn btn-outline-dark me-2"
            onClick={onExitEditMode}
            >
            Отменить
        </button>
        <button
            className= "btn btn-outline-dark"
            onClick={()=>{
                onExitEditMode()
                navigate('/reset_password')
            }
                }
            >
            Забыли пароль?
        </button>
        </dd>
    </> 

const newPasswordForm = 
showNewPasswordForm && <>
    <div className='row mt-3'>
        <dt className='col-sm-3'>Введите новый пароль</dt>
        <dd className='col-sm-4'>
            <input className= {`form-control ${newPasswordInputStyle}`}
            value = {newPassword}
            onChange = {(e)=>{
                setNewPassword(e.target.value)}}
            type = "password"
            />
            <span className="input_error_message">
                {passwordLenghtError&& 'Пароль должен быть длиннее 5 символов'}
            </span>
        </dd>
    </div>
    <div className='row'>
        <dt className='col-sm-3'>Повторите новый пароль</dt>
        <dd className='col-sm-4'>
            <input
            className= {`form-control ${repeatInputStyle}`}
            
            value = {repeatNewPassword}
            onChange = {(e)=>{
                setRepeatNewPassword(e.target.value)}}
            type = "password"
            />
            <span className="input_error_message">
                {passwordRepeatError&& 'Введенные пароли не совпадают'}
            </span>
        </dd>
    </div>
    <div className = "row">
    <button
        className= {`btn btn-secondary me-2 ${submitNewPasswordButtonStyle}`}
        onClick={()=>{
            enterNewPassword(newPassword)
            onExitEditMode();}
            }
        >
        Ок
    </button>
    <button
        className= "btn btn-secondary"
        onClick={onExitEditMode}
        >
        Отмена
    </button>
    </div>
</>

    return(
        <>
            <div className='row'>
            {!editMode?
            <><dt className="col-sm-2 profile_info_field"> Пароль </dt>
                <dd className="col-sm-3">
                <button
                        className=  "btn btn-outline-dark"
                        onClick={()=>{
                            setEditMode(true);
                        }}
                        >
                        Сменить пароль
                    </button>
                </dd>
            </> : 
            currentPasswordForm || newPasswordForm
            }
        </div>
        </>
    )
}


export {ProfilePassword}