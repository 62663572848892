import React from "react";
import { useParams } from 'react-router-dom';
import { RequestNewPasswordForm } from "../../components/requestNewPasswordForm";
import { InputNewPasswordForm } from "../../components/inputNewPasswordForm";

const ResetPasswordForm = ()=>{
    
    const params = useParams()
    const {token} = params

    return (
        <>
            {token === ('' || undefined) ? 
            <RequestNewPasswordForm/>
            : 
            <InputNewPasswordForm
            token = {token}
            />}
        </>
    )
}

export {ResetPasswordForm}