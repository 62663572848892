import React, {useState} from "react";
import { isEmail } from "../helpers/validators";
import DataService from "../service/dataService";
import { useDispatch } from "react-redux";
import { showAlertAction } from "../store/reducers/alertReducer";

const server = new DataService()

const RequestNewPasswordForm = ()=>{

    const [email, setEmail] = useState('')
    const [validValue, setValidValue] = useState(true)
    const dispatch = useDispatch()

    const requestNewPassword = (email)=>{
        server.requestPassword({email})
        .then((res)=>{
            dispatch(showAlertAction({ 
                type: "sucsess",
                text: res.message
            }))
        })
        .catch((err)=>{
            dispatch(showAlertAction({ 
                type: "error",
                text: err.message
            }))
        })
    }

    const inputStyle = validValue? '' : 'is-invalid'
    const buttonStyle = validValue  ? '' : 'disabled'

    return(
        <div>
        <div className="text-center">Введите email, указанный при регистрации</div>
            <input
            className={`form-control ${inputStyle} mt-3`}
            value = {email}
            onChange = {(e)=>{
            setEmail(e.target.value)
            setValidValue(isEmail(e.target.value))
                }
            }
            />
            <button
            className={`btn btn-secondary mt-3 center ${buttonStyle}`}
            onClick = {()=>{ requestNewPassword(email) }}
            >
                Восстановить пароль
            </button>
    </div>
    )
}

export {RequestNewPasswordForm}