const defaultState = {
    text: '',
    show: false,
    type: 'sucsess'
}

const SHOW_ALERT = 'SHOW_ALERT'
const CLOSE_ALERT = 'CLOSE_ALERT'

export default function alertReducer (state = defaultState, action){
    switch(action.type){
        case SHOW_ALERT:
            return {
                ...state,
                text: action.payload.text,
                type: action.payload.type,
                show: true
            }
        case CLOSE_ALERT:
            return {
                ...state, 
                show: false,
                text: defaultState.text
            }
        default:
            return state
    }
}


export const showAlertAction = (payload)=> ({type: SHOW_ALERT, payload})
export const closeAlertAction = ()=> ({type: CLOSE_ALERT})