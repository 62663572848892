import {useContext} from 'react';
import { UserContext } from '../hoc/userContext';
import { changeUserNameAction } from '../store/reducers/userReducer';
import { useDispatch } from 'react-redux';
import { showAlertAction } from '../store/reducers/alertReducer';
import DataService from '../service/dataService';


const server = new DataService()

const useProfileName = ()=>{
    const {userData} = useContext(UserContext)
    const userId = userData.id
    const dispatch = useDispatch()


    const saveNewUserName = (newName) =>{

        const newUserData = {name: newName, id: userId}

        server.updateUserProfile(newUserData).then(()=>{
            dispatch(showAlertAction({
                type: 'sucsess',
                text: 'Имя пользователя было успешно изменено'
            }))

            dispatch(changeUserNameAction(newName))

        }).catch((err)=>{
            dispatch(showAlertAction({
                text: err.message,
                type: 'error'
            }))
        })
    }

    return {
        saveNewUserName
    }
}

export {useProfileName}