import React, {useContext} from "react";
import { useParams } from "react-router-dom";
import {AddContactsForm} from "../../components/addContactsForm"
import { FormGroup, Label, Input , Button} from "reactstrap";
import SingleValueSelect from "../../components/common/singleValueSelect";
import MultiValueSelect from "../../components/common/multiValueSelect/multiValueSelect"
import { UserContext } from "../../hoc/userContext";
import { ModalWindow } from "../../components/common/modalWindow";
import { Navigate } from "react-router-dom";
import { useEditAdvertForm } from "../../hooks/useEditAdvertForm";

const EditAdvertForm = ()=>{

    const {id} = useParams();
    
    const {userData, filterValues} = useContext(UserContext);
    const {styles, cities, instruments, newAdvertTypes} = filterValues

    const {
        redirect, 
        redirectUrl, 
        publishedDate, 
        advertDescription,
        contacts,
        fetchingAdvertData,
        changeAdvertDescription,
        addContacts,
        saveAdvert,
        deleteAdvert,
        setValues,
        anyValues, 
        currentValues
    } = useEditAdvertForm(id, filterValues)

    const {
        setCity,
        setStyle,
        setAdvertType,
        setInstrument
    } = setValues

    const {anyAdvertValue,
        anyInstrumentValue,
        anyStyleValue,
        anyCityValue
    } = anyValues

    const {
        instrument, 
        city, 
        advertType, 
        style
    } = currentValues


    let redirection;

    if(redirect){
        redirection = <Navigate to = {`/adverts/${redirectUrl}`} replace={true}/>
    } else{
        redirection = null
    }

    const newAdvertData = {
        publishedDate,
        userData,
        advertType,
        city,
        style,
        instrument,
        advertDescription,
        contacts
    }

    console.log(newAdvertData)

    if(redirect){
        redirection = <Navigate to = {redirectUrl} replace={true}/>
    } else{
        redirection = null
    }


    if(!fetchingAdvertData){
        return(
        <div className="form_container">
            <h1> Редактирование объявления:
            </h1>
            <SingleValueSelect
                propName = 'advertType'
                inputClass = 'advert_type_input'
                label = 'От кого объявление?'
                containerClass = 'avert-type_container'
                dropDownData = {newAdvertTypes}
                setValue = {setAdvertType}
                currentValue = {advertType}
                anyValue = {anyAdvertValue}
                />
                <SingleValueSelect
                propName = 'city'
                inputClass = 'city_input'
                containerClass = 'city_container'
                label = 'Выберите город'
                dropDownData = {cities}
                setValue = {setCity}
                currentValue = {city}
                anyValue = {anyCityValue}
                />
                <SingleValueSelect
                containerClass = 'instrument_container'
                propName = 'instrument'
                inputClass = 'instrument_input'
                label = 'Выберите инструмент'
                dropDownData = {instruments}
                setValue = {setInstrument}
                currentValue = {instrument}
                anyValue = {anyInstrumentValue}
                />
                <MultiValueSelect
                dropDownData = {styles}
                label='Выберите предпочитаемый музыкальный стиль или жанр'
                propName = 'styles'
                inputClass = 'styles_input'
                setValue = {setStyle}
                anyValue = {anyStyleValue}
                currentValue = {style}
                />
            <FormGroup>
            <Label for="exampleText">
                <h6>Расскажите про себя или про свой проект</h6>
            </Label>
            <Input
            value = {advertDescription}
            id="exampleText"
            name="text"
            type="textarea"
            onChange = {changeAdvertDescription}
            />
            </FormGroup>
            <AddContactsForm
            addContacts = {addContacts}
            defaultContactsList = {contacts}
            />
            <Button
            onClick={()=>{saveAdvert(newAdvertData, id)}}
            className=" mt-5 me-3" >
                Сохранить
            </Button>
            <ModalWindow
            modalMessage = 'Вы точно хотите удалить объявление?'
            modalTitle = 'Удалить объявление'
            buttonText = 'Удалить'
            confirm = {deleteAdvert}
            id = {id}
            />
            {redirection}
        </div>
        )
    }else{
        return null
    }
}

export {EditAdvertForm}