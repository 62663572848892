import React, {useState, useContext} from "react";
import { Form , Input, Label, Button} from "reactstrap";
import SingleValueSelect from "../../components/common/singleValueSelect";
import { useRegistrationForm } from "../../hooks/useRegistrationForm";
import { UserContext } from "../../hoc/userContext";
import { useNewPassword } from "../../hooks/useNewPassword";
import { useNewEmail } from "../../hooks/useNewEmail";

const RegistrationForm = ()=>{

  const {filterValues} = useContext(UserContext);
  const cities = filterValues.cities;

  const [userNameValue, setUserNameValue] = useState('');
  const [userEmailValue, setUserEmailValue] = useState('');
  const [userPasswordValue, setUserPasswordValue] = useState('');
  const [userRepeatPasswordValue, setRepeatUserPasswordValue] = useState('');
  const [userDateOfBirdth, setUserDateOfBirdth] = useState(null);
  const [userCity, setUserCity] = useState('');

  const formData = {
    userNameValue, 
    userEmailValue, 
    userPasswordValue, 
    userRepeatPasswordValue, 
    userDateOfBirdth, 
    userCity
}

  console.log(formData)

  const {userAgeError, createNewUser, checkUserAge, userAge} = useRegistrationForm(formData)
  const {passwordRepeatError, passwordLenghtError} = useNewPassword(userPasswordValue, userRepeatPasswordValue)
  const {notUniqeEmailError, notValidEmailError, checkUniqueEmail, setNotUniqueEmailError} = useNewEmail(userEmailValue)

  const onSubmit = (e)=>{
    e.preventDefault()
    createNewUser()
  }

  const regButtonClass = (!passwordRepeatError && !passwordLenghtError && !userAgeError && !notValidEmailError && !notUniqeEmailError)?
  ""
  : "disabled"

  let passwordRepeatErrorMessage = 'Пароли не совпадают'
  let passwordLenghtMessage = 'Пароль должен быть длиннее 5 символов'
  let notUniqeEmailMessage = `Пользователь с email ${userEmailValue} уже зарегистрирован`
  let notValidEmailMessage = `Введите корректный email`
  let userAgeErrorMessage = `Вам действительно ${userAge} лет?`

  return (
    <Form className ="mt-4">
        <Input
          name="name"
          placeholder="Ваше имя"
          type="text"
          className="mb-3 mt-3"
          onChange = {(e)=>{
            setUserNameValue(e.target.value)
            }
          }
        />
        <Input
          name="email"
          placeholder="Ваш email"
          type="email"
          className="mb-3 mt-3"
          onFocus={()=>{setNotUniqueEmailError(false)}}
          onBlur = {checkUniqueEmail}
          onChange = {(e)=>{
            setUserEmailValue(e.target.value)}}
        />

        <span className="input_error_message mb-2 mt-2 text-center"> 
          { notValidEmailError && notValidEmailMessage} 
        </span>

        <span className="input_error_message mb-2 mt-2 text-center"> 
          { notUniqeEmailError && notUniqeEmailMessage} 
        </span>
        
        <Input
          id="userPassword"
          name="password"
          placeholder="Придумайте пароль"
          type="password"
          className="mb-3 mt-3"
          onChange = {(e)=>{ setUserPasswordValue(e.target.value);
          }}
        />
          <span className="input_error_message mb-2 mt-2 text-center"> 
            { passwordLenghtError && passwordLenghtMessage} 
          </span>
        <Input
          id="repeatPassword"
          name="password"
          placeholder="Повторите пароль"
          type="password"
          className="mb-3 mt-3"
          onChange = {(e)=>{ setRepeatUserPasswordValue(e.target.value)}}
        />

        <span className="input_error_message mb-2 mt-2 text-center"> 
          { passwordRepeatError && passwordRepeatErrorMessage} 
        </span>

        <div className="d-flex flex-wrap align-items-center">
          <Label for="exampleDate" >
            Дата рождения
          </Label>
          <Input
            id="exampleDate"
            name="date"
            placeholder="date placeholder"
            type="date"
            className="date-input ms-2"
            onChange = {(e)=>{
              setUserDateOfBirdth(e.target.value)
              }
            }
            onBlur = {()=>{checkUserAge()}}
          />
          { 
          <span className="input_error_message mb-2 mt-2 text-center">
            {userAgeError && userAgeErrorMessage} 
          </span>}

        </div>
        <SingleValueSelect
          propName = 'city'
          inputClass = 'city_input'
          containerClass = 'city_container'
          label = 'Выберите город'
          dropDownData = {cities}
          setValue = {setUserCity}
          currentValue = {userCity}
          anyValue = ""
          />
        <Button
        className = {`${regButtonClass} nav_button center  mb-3 mt-5`}
        onClick = {(e)=>{onSubmit(e)}}
        >
          Зарегистрироваться
        </Button>
    </Form>
  )
}

export {RegistrationForm}
