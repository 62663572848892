import React, {useState} from "react";

export default function MultiChoiceListItem(props){
    const {item, deleteChoiceItem} = props
    const id = item.id;
    const [checked, setChecked] = useState(true)

    const deleteHandler = ()=>{
        setChecked(false)
    }

    let multiChoiceListItem;

    if(!checked){
        multiChoiceListItem = null
    }   else{
        multiChoiceListItem = 
        <span className="badge rounded-pill ">
        {item.label}
        <span className="delete_item_button"
         onClick = {
            ()=>{
            deleteChoiceItem(id)
            deleteHandler()
            }
        }
        > &#10006;
        </span>
    </span>
    }
    return(
        multiChoiceListItem
    )
}