import React, { useEffect } from 'react'
import ResultItem from '../../components/resultItem';
import {Container, Row} from 'reactstrap';
import { searchSelector, showLoaderSelector } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/common/loader/loader';


export default function SearchResults(){
    const adverts = useSelector(searchSelector)
    const loader = useSelector(showLoaderSelector)
    console.log(loader)

    const scrollToResults = ()=>{
        document.documentElement.scroll({top: 500, behavior: "smooth"})
    }

    useEffect(()=>{
        scrollToResults()
    },[loader])

    if (!adverts){
        return null
    }

    if (loader){

        return (
            <div className='loader_container'>
                <Loader/>
            </div>
        )
    }
    else{
        if(adverts.length === 0){
            return(
            <h3>
                По вашему запросу объявлений не найдено.
         </h3>)
        }

        let advertsList = adverts.map((item)=>{
            return(
                <ResultItem
                key = {String(item.id)}
                advert = {item}
                />
            )
        })

        return(
            <Container className='pt-5'>
                <h3 style={{textAlign: "center"}}> Результаты поиска: </h3>
                <Row lg="2" md="1" sm ="1" >
                    
                    {advertsList}
                </Row>
            </Container>
            
        )
    }
}