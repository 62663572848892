import React, {useState} from 'react';
import { useNewEmail } from '../hooks/useNewEmail';
import { useProfileEmail } from '../hooks/useProfileEmail'

const ProfileEmail = (props)=>{
    const [editMode, setEditMode] = useState(false);
    const {userEmail} = props
    const [inputValue, setInputValue] = useState(userEmail)
    const {updateEmail} = useProfileEmail(userEmail)
    const {notUniqeEmailError, notValidEmailError, checkUniqueEmail, setNotUniqueEmailError} = useNewEmail(inputValue)
    const [showPasswordForm, setShowPasswordForm] = useState(false)
    const [password, setPassword] = useState('')

    const inputStyle = (!notValidEmailError && checkUniqueEmail)? '' : 'is-invalid'
    const buttonStyle = (!notUniqeEmailError && !notValidEmailError && password !=='') ? '' : 'disabled'

    const onExitEditMode = ()=>{
        setInputValue(userEmail)
        setEditMode(false);
        setShowPasswordForm(false)
        setNotUniqueEmailError(false)
    }
    
    return(
        <>
        <div className='row'>
        <dt className="col-md-2 col-sm-6 profile_info_field">{editMode? 'Новый email' : 'Email'}</dt>
            {!editMode?
            <>
                <dd className="col-md-4 col-sm-6">{userEmail}</dd>
                <dd className="col-sm-6">
                    <button
                        className=  "btn btn-outline-dark"
                        onClick={()=>{
                            setEditMode(true);
                            setShowPasswordForm(true)
                        }}
                        >
                        Редактировать
                    </button>
                </dd>
            </> : 
            <>
                <div className="col-sm-4">
                <input className={`form-control ${inputStyle}`}
                value = {inputValue}
                onChange = {(e)=>{
                    setNotUniqueEmailError(false)
                    setInputValue(e.target.value)
                    }
                }
                onBlur = {checkUniqueEmail}
                />
                </div>
                
                <dd className="col-sm-6">
                <button
                    className= {`btn btn-outline-dark me-2 ms-2 ${buttonStyle}`}
                    onClick={()=>{
                        updateEmail(inputValue, password)
                        onExitEditMode()
                        }
                    }
                    >
                    Сохранить
                </button>
                <button
                    className= "btn btn-outline-dark"
                    onClick={onExitEditMode}
                    >
                    Отменить
                </button>
            </dd>
            </>
            }
        </div>
        <span className="input_error_message mb-1 mt-1 col-sm-6 offset-sm-2">
            {(notValidEmailError && 'Некорректный email!') || 
            (notUniqeEmailError && 'Пользователь с таким email уже зарегистрирован!')}
        </span>
        {showPasswordForm && 
        <div className='d-flex'>
            <dt className='col-sm-2'> Введите пароль </dt>
            <dd className='col-sm-4'>
                <input
                className='form-control' 
                type = "password"
                onChange={(e)=>{ setPassword(e.target.value)}}
                value = {password}
                />
            </dd>
        </div>
        }
        </>
    )
}


export {ProfileEmail}