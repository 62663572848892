import React from "react";
import { EditAdvertForm } from "../modules/EditAdvertForm/EditAdvertForm";

const EditAdvertPage = ()=>{
    
        return(
            <EditAdvertForm/>
        )
}

export {EditAdvertPage}