const defaultState = {
    showLoader: false
}

const SHOW_LOADER = "SHOW_LOADER"
const HIDE_LOADER = "HIDE_LOADER"

export const loaderReducer = (state = defaultState, action)=>{
    switch (action.type){
        case SHOW_LOADER:
            return {
                ...state, showLoader: true
            }

        case HIDE_LOADER:
            return {
                ...state, showLoader: false
            }

        default: 
            return state
    }
}

export const showLoaderAction = ()=> ({type: SHOW_LOADER})
export const hideLoaderAction = ()=> ({type: HIDE_LOADER})
