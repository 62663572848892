import {useState, useEffect} from "react"
import DataService from "../service/dataService"
import { useDispatch } from "react-redux"
import { showAlertAction } from "../store/reducers/alertReducer"
import { useNavigate } from "react-router-dom"

const server = new DataService()


const useResetPassword = (token)=>{
    const [inputNewPasswordForm, setInputNewPasswordForm] = useState(false)
    const [userId, setUserId] = useState('')
    const [passwordRepeatError, setPasswordRepeatError] = useState(false)
    const [passwordLenghtError, setPasswordLenghtError] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        server.resetPassword(token)
            .then((res)=>{
                const {id} = res
                setUserId(id)
                setInputNewPasswordForm(true)
                dispatch(showAlertAction({ 
                  type: "sucsess",
                  text: res.message
              }))
            })
            .catch((err)=>{
              dispatch(showAlertAction({ 
                type: "error",
                text: err.message
            }))
            })
    },[token])

    const resetValidationErrors = ()=>{
        setPasswordRepeatError(false)
        setPasswordLenghtError(false)
    }

    const passwordRepeatValidation = (userPasswordValue, userRepeatPasswordValue)=>{
        if(userPasswordValue !== userRepeatPasswordValue && userRepeatPasswordValue !== ''){
          setPasswordRepeatError(true)
        } else{
          setPasswordRepeatError(false)
        }
      }
    
      const passwordLenghtValidation = (userPasswordValue)=>{
        if (userPasswordValue.length < 6){
          setPasswordLenghtError(true)
        } else{
          setPasswordLenghtError(false)
        }
      }
      const enterNewPassword = (password)=>{
        server.changeUserPassword({id: userId, password})
        .then((res)=>{
        dispatch(showAlertAction({
            type: "sucsess",
            text: res.message
        }))
        navigate('/login')
        })
        .catch((err)=>{
            dispatch(showAlertAction({ 
                type: "error",
                text: err.message
            }))
        })
    }


    return{
        inputNewPasswordForm, 
        userId, 
        resetValidationErrors,
        passwordLenghtValidation,
        passwordRepeatValidation,
        passwordLenghtError,
        passwordRepeatError,
        enterNewPassword
    }
}
export {useResetPassword}