import React, {useState} from "react";
import MultiValueListItem from './mutiValueListItem'
import MultiValueChoiceItem from './multiValueChoiceItem'
import { FormGroup, Label, Input, ListGroup } from 'reactstrap'
import { useMultiValueSelect } from "../../../hooks/useMultiValueSelect";

export default function MultiValueSelect (props){

  const {
  dropDownData = [], 
  anyValue,
  propName = '', 
  inputClass = '',
  label, 
  setValue,
  currentValue = []
} = props;

  const {getNewDropDownData, multiChoice, inputValue, setInputValue, choiceList, deleteChoiceItem} = useMultiValueSelect(dropDownData, anyValue, currentValue, setValue)
  const [background, setBackground] = useState(false)
  const [inputFocus, setInputFocus] = useState(false);
  let [filteredData, setFilteredData] = useState(dropDownData)

  let dropdownStyle, dropDownList, bgClass, choices;

  inputFocus? dropdownStyle = '': dropdownStyle = 'hide'
  !background? bgClass = 'dropdown_bg-hide': bgClass = ''

  filteredData.length > 0 ?
    dropDownList =
    filteredData.map((item)=>{
      return(
        <MultiValueListItem
        setValue = {setValue}
        key = {item._id}
        item = {item}
        choiceList = {choiceList}
        setInputFocus = {setInputFocus}
        setBackground = {setBackground}
        multiChoice = {multiChoice}
        >
        {item.label}
        </MultiValueListItem>
      )
    })
  :
    dropDownList =
      <MultiValueListItem
      setBackground = {setBackground}
      setInputFocus = {setInputFocus}
    onClick={()=>{ setInputValue(''); setInputFocus(false)}}
    >
        {'нет данных'}
    </MultiValueListItem>

choiceList.length > 0 ?
    choices = choiceList.map((item)=>{
      return(
          <MultiValueChoiceItem
          item = {item}
          deleteChoiceItem = {deleteChoiceItem}
          key = {item.id}
          />
      )
    })
  :
    choices = null

  return (
    <FormGroup className={`mt-4 input_container`}>
      <div
        className={`dropdownBg ${bgClass}`}
        onClick = {()=>{
          setBackground(false)
          setInputFocus(false);
        }}
        ></div>
    <Label htmlFor={`${propName}`}>
      <h6 >{label}</h6>
    </Label>
    <Input type="text" id={`${propName}`} className = {`${inputClass} text_input`}
    onFocus={()=> {
      setFilteredData(dropDownData)
      setInputFocus(true);
      setBackground(true)
      setInputValue('')
      }
    }
    value = {inputValue}
    onChange = {(e)=>{
      setInputValue(e.target.value)
      setFilteredData(getNewDropDownData(e, dropDownData, anyValue))
    }}
    />
    <ListGroup className={ `dropdown ${dropdownStyle}` } >
    {dropDownList}
  </ListGroup>

  <ul className = " marked_items">
    { choices }
  </ul>
  </FormGroup>
  );
}
