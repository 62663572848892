import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../common/layout/layout";
import { SearchPage } from "../../pages/SearchPage";
import { LoginPage } from "../../pages/LoginPage";
import { RegistrationPage } from "../../pages/RegistrationPage";
import { ProfilePage } from "../../pages/ProfilePage";
import { NewAdvertPage } from "../../pages/NewAdvertPage";
import { SinglePage } from "../../pages/SinglePage";
import { EditAdvertPage } from "../../pages/EditAdvertPage";
import { RequireAuth } from "../../hoc/requireAuth";
import { UserProvider } from "../../hoc/userContext";
import { InfoAlert } from "../common/alert/alert";
import { ActivatePage } from "../../pages/ActivatePage";
import { ResetPasswordPage } from "../../pages/ResetPasswordPage";
import { CheckAuth } from "../../hoc/checkAuth";
import { VKLoginPage } from "../../pages/VkLoginPage";
import "./app.sass";

const App = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/search" element={<SearchPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login/vk" element={<VKLoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route
            path="/reset_password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/new_advert"
            element={
              <RequireAuth>
                <NewAdvertPage />
              </RequireAuth>
            }
          />

          <Route
            path="/adverts/:id"
            element={
              <CheckAuth>
                <SinglePage />
              </CheckAuth>
            }
          />
          <Route
            path="/adverts/:id/edit"
            element={
              <RequireAuth>
                <EditAdvertPage />
              </RequireAuth>
            }
          />
          <Route path="/activate_user/:link" element={<ActivatePage />} />
        </Route>
      </Routes>
      <InfoAlert />
    </UserProvider>
  );
};

export { App };
