import DataService from "../service/dataService";
import { useDispatch, useSelector} from "react-redux";
import { setAdvertsDataAction } from "../store/reducers/searchReducer";
import { searchSelector } from "../store/selectors";

const useFavoriteButton = (advertData, userId)=>{
    const dispatch = useDispatch()
    const {id} = advertData
    const advertId = id
    const advertsData = useSelector(searchSelector)
    const server = new DataService()

    const checkFavoriteStatus = (userId)=>{
        return advertData.likes.includes(userId)
    }

    let favoriteStatus = checkFavoriteStatus(userId)

    const getNewAdvertData = (data, userId)=>{
        let newData, newList;
        let {likes} = data
        let isFavorite = likes.includes(userId)
        if (likes.length === 0){
            newList = [userId]
            newData = {...data, likes: newList }
        }
        else{
            if(isFavorite){
                newList = likes.filter((item)=>{
                    return item !== userId
                })
                
            } else{
                newList = [...likes, userId]
            }
            newData = {...data, likes: newList}
        }
        return newData
    }

    const updateAdvertsData = ()=>{
        const newAdvertData = getNewAdvertData(advertData, userId)
        const newAdvertsData = advertsData.map((item)=>{
            if(item.id === advertId){
                item = newAdvertData
                return item
            }
            return item
        })
        dispatch(setAdvertsDataAction(newAdvertsData))
        server.editAdvert(newAdvertData, advertId)
    }
    return {favoriteStatus, updateAdvertsData}
}

export {useFavoriteButton}