import React, {useState} from 'react';
import { useProfileName } from '../hooks/useProfileName';

const ProfileName = (props)=>{
    const [editMode, setEditMode] = useState(false);
    const {label, userName} = props
    const {saveNewUserName} = useProfileName()
    const [inputValue, setInputValue] = useState(userName)

    return(
        <div className='row'><dt className="col-md-2 col-sm-6 profile_info_field">{label}</dt>
            {!editMode?
            <>
                <dd className="col-md-4 col-sm-6">{userName}</dd>
                <dd className="col-sm-6">
                    <button
                        className=  "btn btn-outline-dark"
                        onClick={()=>{
                            setEditMode(true);
                        }}
                        >
                        Редактировать
                    </button>
                </dd>
            </> : 
            <>
                <div className="col-sm-4">
                <input className="form-control"
                value = {inputValue}
                onChange = {(e)=>{setInputValue(e.target.value)}}
                />
                </div>
                <dd className="col-sm-6">
                <button
                    className= "btn btn-outline-dark ms-2 me-2"
                    onClick={()=>{
                        saveNewUserName(inputValue)
                        setEditMode(false);}
                    }
                    >
                    Сохранить
                </button>
                <button
                    className= "btn btn-outline-dark"
                    onClick={()=>{
                        setInputValue(userName)
                        setEditMode(false);}
                    }
                    >
                    Отменить
                </button>
            </dd>
            </>
            }
        </div>
    )
}


export {ProfileName}