import React, {useState, useRef} from "react"
import { Modal, Button, ModalHeader, ModalBody , ModalFooter, Input } from "reactstrap"
import { useAvatarEditWindow } from "../hooks/useAvatarEditWindow"
import { useSelector } from "react-redux"
import {showLoaderSelector} from '../store/selectors'
import { Loader } from '../components/common/loader/loader'

const AvatarEditWindow = (props)=>{

    const {setAvatarData, userData} = props
    const [modal, setModal] = useState(false);
    const avatarBorderSize = 250
    const [imageDataURL, setImageDataURL] = useState(null)
    const loader = useSelector(showLoaderSelector)

    const toggle = ()=>{
        setModal(!modal)
        resetEditSettings()
    }

    const canvas = useRef(null)
    const loadedImage = useRef(null)

    const {
        editMode,
        showEditResult,
        imageStyles,
        imageStyleSetters,
        sendFile,
        cropAvatar,
        resetEditSettings,
        handleDeleteAvatar,
        getImageDataURL,
        avatarImageSize,
        onChangeImageSize,
    } = useAvatarEditWindow(setAvatarData, userData, canvas, avatarBorderSize, imageDataURL, loadedImage)

    const onChangeInput = async (e)=>{
        const file = e.target.files[0]
        if (file){
            setImageDataURL(await getImageDataURL(file))
        }
    }

    const {
        increase, 
        decrease, 
        moveLeft,
        moveRight,
        moveUp,
        moveDown,
        onMouseDown,
        onTouchStart
    } = imageStyleSetters

    return(
        <div className="avatar_edit_window">
            <button
            className="btn btn-outline-dark"
            onClick={toggle}
            >
                Редактировать
            </button>
        <Modal isOpen={modal} toggle={toggle} size= 'xl'>
        <ModalHeader toggle={toggle}> Редактирование аватара пользователя </ModalHeader>
        <ModalBody>
    <Input
    className='mt-3'
    bsSize='md'
    id="avatarInput"
    name="filedata"
    type="file"
    onChange={(e)=>{
        onChangeInput(e)}}
    />
    {loader?
    <div className="loader_container">
        <Loader/>
    </div> :
    null
    }
        {editMode && 
        <div className="d-flex justify-content-around flex-wrap ">
            {!showEditResult && 
            <div className=" edit_avatar_settings  pt-3 me-3">
            <div className="text-center mb-3"> Для выбора фрагмента перетаскивайте изображение </div>
            <div
                ref={loadedImage}
                onMouseDown = {onMouseDown}
                onTouchStart={onTouchStart}
                className = "edit_avatar_screen mx-auto"
                style={imageStyles}
                >
            </div>
            
                {/* <div className='mt-3 edit_avatar_controls d-flex justify-content-center'>
                    <button
                    onClick = {increase}
                    className='btn btn-outline-secondary me-2'> + </button>
                    <button
                    onClick = {decrease}
                    className='btn btn-outline-secondary me-2' > - </button>
                    <button
                    onClick={moveLeft}
                    className='btn btn-outline-secondary me-2'> {"<"} 
                    </button>
                    <button
                    onClick = {moveDown}
                    className='btn btn-outline-secondary me-2'> v </button>
                    <button
                    onClick = {moveUp}
                    className='btn btn-outline-secondary me-2'> ^ </button>
                    <button
                    onClick = {moveRight}
                    className='btn btn-outline-secondary me-2'> {">"}
                    </button>
                </div> */}
                <Input
                min = {0}
                max = {2}
                step={0.05}
                value = {avatarImageSize}
                onChange={onChangeImageSize}
                id="exampleRange"
                name="range"
                type="range"
                />
                <div className="text-center"> {' - '}Масштаб{' + '} </div>
                
            <button
                onClick = {cropAvatar}
                className='btn btn-secondary d-block mx-auto mt-3'> Готово
            </button>
        </div>
            }
                <div className={`${!showEditResult? 'hide': ''} edit_avatar_result pt-3 ms-3`}>
                    <canvas
                    className="canvas"
                    ref={canvas}
                    id = "avatar_canvas"
                    width = {avatarBorderSize}
                    height = {avatarBorderSize}
                    >
                    </canvas>
                <button
                    onClick = {()=>{
                        sendFile()
                        setModal (false)
                    }}
                    className='btn btn-secondary d-block mx-auto mt-3'> Сохранить 
                </button>
                <button
                className="btn btn-secondary d-block mx-auto mt-3"
                onClick={resetEditSettings}>
                Отменить
                </button>
                </div>
            
        </div>
        }

        </ModalBody>
        <ModalFooter>
        <Button
        className='mt-2'
        color= "danger"
        onClick = {handleDeleteAvatar}
        >
            Удалить аватар
        </Button>
        </ModalFooter>
      </Modal>
        </div>
    )
}

export {AvatarEditWindow}