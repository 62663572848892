import React from "react";
import { NavButton } from "./common/navButton/navButton";
import { Card, CardTitle, CardSubtitle, CardBody, Col } from "reactstrap";
import StyleItem from "./common/styleItem";
import { FavoriteButton } from "./favoriteButton/favotiteButton";
import { printDate } from "../service/dateFormater";
import { authSelector } from "../store/selectors";
import { useSelector } from "react-redux";
import { Avatar } from "./avatar";

export default function ResultItem(props) {
  const { advert } = props;
  const auth = useSelector(authSelector);

  const styleNames = advert.style.map((style) => {
    return <StyleItem style={style} key={String(style.id)} />;
  });

  let favoriteButton;
  if (auth) {
    favoriteButton = <FavoriteButton advertData={advert} />;
  } else {
    favoriteButton = null;
  }

  const advertDate = printDate(advert.publishedDate);

  return (
    <Col className="result_item mt-5">
      <Card>
        <CardBody>
          <CardTitle tag="h4">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex author_info">
                <div className="me-3">
                  <Avatar width="80" height="80" src={advert.authorAvatar} />
                </div>
                <div className="">
                  {advert.authorName}
                  <CardSubtitle className="mb-4 text-muted" tag="h6">
                    {advert.advertType.label}
                  </CardSubtitle>
                </div>
              </div>
              {favoriteButton}
            </div>
          </CardTitle>

          <dl className="row">
            <dt className="col-sm-3 prop_name">Город</dt>
            <dd className="col-sm-9 prop_value">{advert.city.label}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-3 prop_name">Инструмент</dt>
            <dd className="col-sm-9 prop_value">{advert.instrument.label}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-3 prop_name">Стиль</dt>
            <dd className="col-sm-9 prop_value">{styleNames}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-3 prop_name">Дата публикации</dt>
            <dd className="col-sm-9 prop_value">{advertDate}</dd>
          </dl>
          <NavButton
            btnClass="center"
            to={`/adverts/${advert.id}`}
            id={advert.id}
          >
            Подробнее
          </NavButton>
        </CardBody>
      </Card>
    </Col>
  );
}
