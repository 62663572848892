import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

const Footer = ()=>{
    const location = new useLocation()

    let redirection;

    if (location.pathname === '/'){
        redirection = <Navigate to = {`/search`} replace={true}/>
    } else{
        redirection = null
    }

    return(
        <div className='footer'>
            {redirection}

        </div>
    )
}

export {Footer}