import React from "react";
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';

const NavButton = ({children, to, btnClass})=>{
    return(
        <Button className={` nav_link ${btnClass}`}>
            <Link to = {to}>
                {children}
            </Link>
        </Button>
    )
}

export {NavButton}