import React from 'react';
import { Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {closeAlertAction} from '../../../store/reducers/alertReducer';

function InfoAlert() {
    const dispatch = useDispatch()
    const alertState = useSelector((state)=>{
      return state.alert
    })

    const {type, text, show} = alertState

  const onDismiss = () => {
    dispatch(closeAlertAction())
    
};
let alert;

  if(type === 'sucsess'){
    alert = 
    <Alert isOpen={show} toggle={onDismiss}>
        {text}
  </Alert>
  }
  if (type === 'error'){
    const color = 'danger'

    alert = 
    <Alert color = {color} isOpen={show} toggle={onDismiss}>
      {text}
    </Alert>
  }


  return (
    alert
  );
}

export {InfoAlert};