export default class FiltersDataService {

    searchAdvertTypes = [
        {label : 'Объявления от музыкантов', _id: "0", name: "fromMusicians" }, 
        {label : 'Объявления от групп', _id: "1", name: "fromGroups"},
        {label : 'Объявления от всех', _id: "2", name: "any", default: true}
    ];

    newAdvertTypes = [
        {
            name: 'fromMusicians', label: 'Музыкант ищет группу', _id: 0, default: true
        },
        {
            name: 'fromGroups', label: 'Группа ищет музыканта', _id:1
        }
    ]
}
