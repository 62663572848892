import { useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { showAlertAction } from '../store/reducers/alertReducer'
import { setUserAvatarAction, deleteUserAvatarAction } from '../store/reducers/userReducer'
import DataService from '../service/dataService'
import { hideLoaderAction, showLoaderAction } from '../store/reducers/loaderReducer'

const useAvatarEditWindow = (setAvatarData, userData, canvas, avatarBorderSize, imageDataURL, loadedImage)=>{

    useEffect(()=>{
    if(imageDataURL){
        setEditMode(true)
        const image = new Image()
        image.src = imageDataURL
        image.crossOrigin = 'Anonymous';
        image.onload = ()=>{
            const imgHeight = image.height
            const imgWidth = image.width
            setAvatarImageHeight(imgHeight)
            setAvatarImageWidth(imgWidth)
            dispatch(hideLoaderAction())
            }
        }
    }, [imageDataURL])
    
    const [editMode, setEditMode] = useState(false)
    const [showEditResult, setShowEditResult] = useState(false)
    const [resultFile, setResultFile] = useState(null)
    const dispatch = useDispatch()
    const server = new DataService()

    const [avatarImageHeight, setAvatarImageHeight] = useState(0)
    const [avatarImageWidth, setAvatarImageWidth] = useState(0)
    const [avatarImageSize, setAvatarImageSize] = useState(1)
    const [avatarPositionX, setAvatarPositionX] = useState(0)
    const [avatarPositionY, setAvatarPositionY] = useState(0)

    const moveLeft = ()=>{
        if(avatarPositionX === 0){
            return
        }
        
        const posX = (avatarPositionX + 2) * 0.01*  avatarImageWidth * avatarImageSize
        setAvatarPositionX(posX)
    }

    const moveRight = ()=>{
        if(avatarPositionX === 100){
            return
        }
        const posX = (avatarPositionX - 2) * 0.01*  avatarImageWidth * avatarImageSize
        setAvatarPositionX(posX)
    }

    const moveUp = ()=>{
        
        const posY = (avatarPositionY + 2) * 0.01*  avatarImageHeight * avatarImageSize
        setAvatarPositionY(posY)
    }

    const moveDown = ()=>{
        const posY = (avatarPositionY - 2) * 0.01*  avatarImageHeight * avatarImageSize
        setAvatarPositionY(posY)
    }

    const increase = ()=>{
        setAvatarImageSize(avatarImageSize + 0.05)
    }

    const decrease = ()=>{
        setAvatarImageSize(avatarImageSize - 0.05)
    }

    const onChangeImageSize = (e)=>{
        setAvatarImageSize(e.target.value)
    }

    const imageStyles = 
        {
            backgroundImage: `url(${imageDataURL})`,
            backgroundPosition: `${avatarPositionX}px ${avatarPositionY}px`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: `${Math.round(avatarImageWidth * avatarImageSize)}px ${Math.round(avatarImageHeight * avatarImageSize)}px`,
            width: `${avatarBorderSize}px`,
            height: `${avatarBorderSize}px`
        }


    const onMouseDown = (e)=>{
        const downX = e.clientX
        const downY = e.clientY

        const moveTo = (x, y)=>{
            loadedImage.current.style.backgroundPositionX = `${avatarPositionX + (x - downX)}px`
            loadedImage.current.style.backgroundPositionY = `${avatarPositionY + (y - downY)}px`
        }
    
        const stopMoveAt = (x, y)=>{
            setAvatarPositionX (avatarPositionX + x - downX)
            setAvatarPositionY (avatarPositionY + y - downY)
        }
    
        loadedImage.current.onmousemove = (e)=>{
            moveTo(e.pageX, e.pageY)
        }
    
        loadedImage.current.onmouseup = (e)=>{
            const stopX = e.pageX
            const stopY = e.pageY
            stopMoveAt(stopX, stopY)
            loadedImage.current.onmousemove = null   
        }
    }

    const onTouchStart = (e)=>{
        const downX = e.touches[0].clientX
        const downY = e.touches[0].clientY

        const moveTo = (x, y)=>{
            loadedImage.current.style.backgroundPositionX = `${avatarPositionX + (x - downX)}px`
            loadedImage.current.style.backgroundPositionY = `${avatarPositionY + (y - downY)}px`
        }

        const stopMoveAt = (x, y)=>{
            setAvatarPositionX (avatarPositionX + x - downX)
            setAvatarPositionY (avatarPositionY + y - downY)
        }

        loadedImage.current.ontouchmove = (e)=>{
            moveTo(e.touches[0].clientX, e.touches[0].clientY)
        }

        loadedImage.current.ontouchend = (e)=>{
            const stopX = e.changedTouches[0].clientX
            const stopY = e.changedTouches[0].clientY
            stopMoveAt(stopX, stopY)
            loadedImage.current.onTouchMove = null
        }
    }



    const cropAvatar = ()=>{
        setShowEditResult(true)
        const params = {
            width : Math.round(avatarImageWidth * avatarImageSize),
            height: Math.round(avatarImageHeight * avatarImageSize),
            x: Math.abs(Math.round(avatarPositionX/avatarImageSize)),
            y: Math.abs(Math.round(avatarPositionY/avatarImageSize)),
            newWidth : Math.round(avatarBorderSize/avatarImageSize),
            newHeight : Math.round(avatarBorderSize/avatarImageSize),
            avatarImageSize
        }
        console.log(params)
        const ctx = canvas.current.getContext('2d')
        const image = new Image()
        image.src = imageDataURL
        image.onload = ()=>{
            ctx.drawImage(image, params.x, params.y, params.newWidth, params.newHeight, 0, 0, avatarBorderSize, avatarBorderSize)
            canvas.current.toBlob((blob)=>{
                const fileName = `avatar${Date.now()}.png`
                const resFile = new File ([blob], fileName)
                setResultFile(resFile)
                console.log(resFile)
            }, "image/png")
        }
    }

    const resetEditSettings = ()=>{
        setAvatarImageSize(1)
        setAvatarPositionX(0)
        setAvatarPositionY(0)
        setEditMode(false)
        setShowEditResult(false)
    }

    const getImageDataURL = (file)=>{
        return new Promise((resolve)=>{
            dispatch(showLoaderAction())
            const reader = new FileReader()
            
            reader.onload = ()=>{
                resolve(reader.result)
                
            }
            reader.readAsDataURL(file)
        })
    }

    const sendFile = ()=>{
        const data = new FormData()
        data.append('id', userData.id)
        data.append('avatar', resultFile)
        server.uploadAvatar(data)
        .then((res)=>{
            const {src} = res
            dispatch(showAlertAction({
                type: 'sucsess',
                text: res.message
            }))
            setAvatarData(src)
            dispatch(setUserAvatarAction(src))
        })
        .catch((err)=>{
            dispatch(showAlertAction({
                type: 'error',
                text: err.message
            }))
            console.log(err)
        })
        resetEditSettings()
    }


    const handleDeleteAvatar = ()=>{
        server.deleteAvatar(userData.id)
        .then((res)=>{
            setAvatarData(null)
            dispatch(deleteUserAvatarAction())
            dispatch(showAlertAction({
                type: 'sucsess',
                text: res.message
            }))
        })
        .catch((err)=>{
            dispatch(showAlertAction({
                status: "error",
                type: err.message
            }))
        })
        resetEditSettings()
    }

    

    const imageStyleSetters = {
        increase, 
        decrease, 
        moveLeft,
        moveRight,
        moveUp,
        moveDown,
        onMouseDown,
        onTouchStart
    }

    return {
        avatarPositionX,
        avatarPositionY,
        avatarImageWidth,
        avatarImageHeight,
        avatarImageSize,
        editMode,
        showEditResult,
        imageStyles,
        imageStyleSetters,
        sendFile,
        cropAvatar,
        resetEditSettings,
        handleDeleteAvatar,
        getImageDataURL,
        onChangeImageSize
    }
}

export {useAvatarEditWindow}