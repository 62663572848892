import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { showAlertAction } from '../store/reducers/alertReducer';
import { LoginPage } from '../pages/LoginPage';
import DataService from '../service/dataService';
import { useParams } from 'react-router-dom';


const ActivatePage = ()=>{
    const params = useParams()
    const link = params.link;
    const server = new DataService()
    const dispatch = useDispatch()

    useEffect(()=>{
        server.getActivateStatus(link)
        .then((ActivateStatus)=>{
            if(ActivateStatus){
                dispatch(showAlertAction({
                    text: 'Пользователь был успешно активирован',
                    type: 'sucsess'
                }))
            }
            else{
                dispatch(showAlertAction({
                    text: 'Пользователь не активирован',
                    type: 'error'
                }))
            }
        })
    }, [])

    return(
        <LoginPage
        />
    )
}

export {ActivatePage}