import React, {useState, useContext} from "react";
import DataService from "../../service/dataService";
import { AddContactsForm } from "../../components/addContactsForm";
import { FormGroup, Label, Input , Button } from "reactstrap";
import SingleValueSelect from "../../components/common/singleValueSelect";
import MultiValueSelect from "../../components/common/multiValueSelect/multiValueSelect";
import { UserContext } from "../../hoc/userContext";
import {Navigate} from 'react-router-dom'
import { useDispatch } from "react-redux";
import { showAlertAction } from "../../store/reducers/alertReducer";
import {getDefaultValue} from '../../helpers/getDefaultValue'

const NewAdvertForm = ()=>{

    const [redirectUrl, setRedirectUrl] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [advertDescription, setAdvertDescription] = useState('')
    const {userData, filterValues} = useContext(UserContext);
    const userId = userData.id
    const {styles, cities, instruments, newAdvertTypes } = filterValues
    const [contacts, setContacts] = useState([]);
    const dispatch = useDispatch()
    const anyCity = getDefaultValue(cities)
    const anyInstrument = getDefaultValue(instruments)
    const anyStyle = [getDefaultValue(styles)]
    const anyAdvertType = getDefaultValue(newAdvertTypes)

    const publishedDate = new Date()

    const [instrument, setInstrument] = useState(anyInstrument)
    const [city, setCity] = useState(anyCity)
    const [style, setStyle] = useState(anyStyle)
    const [advertType, setAdvertType] = useState(anyAdvertType)

    const changeAdvertDescription = (e)=>{
        setAdvertDescription(e.target.value) 
    }

    const addContacts = (contacts)=>{
        setContacts(contacts)
    }

    const saveAdvert = (data)=>{
        const server = new DataService()
        server.postAdvertData(data)
        .then((response)=>{
            dispatch(showAlertAction({
                type: 'sucsess',
                text: response.message
            }))
            setRedirectUrl(response.advertId)
            setRedirect(true)
        })
        .catch((error)=>{
            dispatch(showAlertAction({
                type: 'error',
                text: error.message
            }))
        })
    }

    let redirection;

    if(redirect){
        redirection = <Navigate to = {`/adverts/${redirectUrl}`} replace={true}/>
    } else{
        redirection = null
    }

    const newAdvertData = {
        publishedDate,
        userId,
        advertType,
        city,
        style,
        instrument,
        advertDescription,
        contacts
    }


    return(
        <>
            <h1 className="text-center main-title mt-5" > Новое объявление </h1>
            <div className="form_container  mb-5">
                <SingleValueSelect
                propName = 'advertType'
                inputClass = 'advert_type_input'
                label = 'От кого объявление?'
                containerClass = 'avert-type_container'
                dropDownData = {newAdvertTypes}
                setValue = {setAdvertType}
                currentValue = {advertType}
                anyValue = {anyAdvertType}
                />
                <SingleValueSelect
                propName = 'city'
                inputClass = 'city_input'
                containerClass = 'city_container'
                label = 'Выберите город'
                dropDownData = {cities}
                setValue = {setCity}
                anyValue = {anyCity}
                currentValue = {city}

                />
                <SingleValueSelect
                containerClass = 'instrument_container'
                propName = 'instrument'
                inputClass = 'instrument_input'
                label = 'Выберите инструмент'
                dropDownData = {instruments}
                setValue = {setInstrument}
                currentValue = {instrument}
                anyValue = {anyInstrument}

                />
                <MultiValueSelect
                dropDownData = {styles}
                label='Выберите предпочитаемый музыкальный стиль или жанр'
                propName = 'styles'
                inputClass = 'styles_input'
                setValue = {setStyle}
                anyValue = {anyStyle}

                />
                <FormGroup>
                    <Label for="exampleText">
                        <h6>Расскажите про себя или про свой проект</h6>
                    </Label>
                    <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange = {changeAdvertDescription}
                    />
                </FormGroup>
                <AddContactsForm
                    addContacts = {addContacts}
                    defaultContactsList = {contacts}
                />
                <Button
                    onClick={()=>{saveAdvert(newAdvertData)}}
                    className="center mt-5" >
                        Опубликовать
                </Button>
                {redirection}
            </div>
        </>
    )
}

export {NewAdvertForm}