import DataService from "../service/dataService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInAction } from "../store/reducers/userReducer";
import { showAlertAction } from "../store/reducers/alertReducer";

const useAuthForm = () => {
  const server = new DataService();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tryAuthorization = (email, password) => {
    server
      .login({ email, password })
      .then((userData) => {
        console.log(userData);
        dispatch(signInAction(userData));
        localStorage.setItem("token", userData.token);
        navigate("/search");
      })
      .catch((error) => {
        dispatch(
          showAlertAction({
            text: error.message,
            type: "error",
          })
        );
      });
  };

  return { tryAuthorization };
};

export { useAuthForm };
