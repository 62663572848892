import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import useQuery from "../hooks/useQuery";
import DataService from "../service/dataService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInAction } from "../store/reducers/userReducer";

const VKLoginPage = () => {
  const [appToken, setAppToken] = useState(null);
  const params = useQuery();
  const payload = params.get("payload");

  const dataService = new DataService();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (payload) {
      dataService
        .vkLogin(payload)
        .then((res) => {
          return res.json();
        })
        .then((userData) => {
          dispatch(signInAction(userData));
          localStorage.setItem("token", userData.token);
          navigate("/search");
        });
    }
  }, [params]);

  return (
    <Container className="d-flex align-items-center flex-column mt-5">
      <h1 className="text-center main-title"> Вход через VK ID </h1>
    </Container>
  );
};

export { VKLoginPage };
