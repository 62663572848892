const defaultState = {
    advertsData : null
}

const SET_ADVERTS_DATA = 'SET_ADVERTS_DATA'
const CLEAR_ADVERTS_DATA = 'CLEAR_ADVERTS_DATA'


export const searchReducer = (state = defaultState, action)=>{
    switch (action.type) {
        case SET_ADVERTS_DATA:
        return{
            ...state, advertsData: action.payload
        }
        case CLEAR_ADVERTS_DATA:
        return defaultState
    
        default:
            return state
    }

}

export const setAdvertsDataAction = (advertsData)=>{
    return {
        type: SET_ADVERTS_DATA,
        payload: advertsData
    }
}

export const clearAdvertsDataAction = ()=>{
    return {
        type: CLEAR_ADVERTS_DATA
    }
}

