import React , {useContext, useState, useEffect} from "react";

import { ProfileAdverts } from "../components/profileAdrverts";
import { UserContext } from "../hoc/userContext";
import DataService from "../service/dataService";
import { FavoriteAdverts } from "../components/favoriteAdverts";
import { useDispatch } from "react-redux";
import { showLoaderAction, hideLoaderAction } from "../store/reducers/loaderReducer";
import { UserInfo } from "../modules/UserInfo/UserInfo";
import { Loader } from "../components/common/loader/loader";


const ProfilePage = ()=>{
    const {userData} = useContext(UserContext);
    const [favoriteAdvertsData, setFavoriteAdvertsData] = useState([])
    const [userAdverts, setUserAdverts] = useState([])
    const [fetching, setFetching] = useState(true)

    const server = new DataService()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(showLoaderAction())
        server.getUserAdverts(userData.id)
        .then((advertsData)=>{
            const {userAdverts, resultFavoriteAdverts} = advertsData
            setUserAdverts(userAdverts)
            setFavoriteAdvertsData(resultFavoriteAdverts)
        })
        .then(()=>{
            setFetching(false)
        })
        .finally(()=>{
            dispatch(hideLoaderAction())
        })
    }, [])

    return(
    <>
    <h1 className="text-center main-title mt-5 mb-5" > Профиль </h1>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
            <button className="nav-link active" id="user_info_tab" data-bs-toggle="tab" data-bs-target="#user_info" type="button" role="tab" aria-controls="user_info" aria-selected="true">Информация</button>
        </li>
        <li className="nav-item" role="presentation">
            <button className="nav-link " id="user_adv_tab" data-bs-toggle="tab" data-bs-target="#user_adv" type="button" role="tab" aria-controls="user_adv" aria-selected="false">Мои объявления</button>
        </li>
        <li className="nav-item" role="presentation">
            <button className="nav-link" id="favor-tab" data-bs-toggle="tab" data-bs-target="#favorite_adverts" type="button" role="tab" aria-controls="favorite_adverts" aria-selected="false">Избранное</button>
        </li>
    </ul>
    <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="user_info" role="tabpanel" aria-labelledby="user_info_tab" tabIndex="0">
        <dl className="row">
            {fetching?
            <div className="loader_container">
                <Loader/>
            </div> :
            <UserInfo/>
            }
        </dl>
        </div>
        <ProfileAdverts
        userAdverts = {userAdverts}
        />
        <FavoriteAdverts
        favoriteAdvertsData = {favoriteAdvertsData}
        />
    </div>
    </>
    )
}

export {ProfilePage}