import React, {useState, useEffect} from "react"

const useSingleValueSelect = (data)=>{
    
    const [dropDownData, setDropDownData] = useState([])
    const [mainList, setMainList] = useState([])
    const [defaultValue, setDefaultValue] = useState({})

    useEffect(()=>{
        if (data.length < 1){
            return
        }
        setDropDownData(data)
        const list = data.filter((item)=>{
            return item.mainList === true
        })
        list.length > 0 ?
        setMainList(list)
        :
        setMainList(data)

    }, [data])

    const getNewDropDownData = (e, dropDownData, anyValue, mainList)=>{
        const searchFrase = e.target.value.toLowerCase();
        let newData = [];
        if (searchFrase.length > 0){
            newData = dropDownData.filter((item)=>{
            return item.label.toLowerCase().indexOf(searchFrase) > -1
            });
            if (newData.length > 0){
                return newData
            }
            else{
                return [anyValue]
              }
            }
          else{
            return mainList
          }
    }

    return {
        getNewDropDownData,
        defaultValue,
        mainList
    }
}

export {useSingleValueSelect}