import { useState, useEffect } from "react"
import DataService from "../service/dataService"
import { useDispatch } from "react-redux"
import {setAdvertsDataAction} from "../store/reducers/searchReducer" 
import { showLoaderAction, hideLoaderAction } from "../store/reducers/loaderReducer"


const server = new DataService()
const useSearchForm = (filterChoices)=>{

    const [advertsData, setAdvertsData] = useState([])
    const [fetching, setFetching] = useState(false)
    const [requestString, setRequestString] = useState('')

    const dispatch = useDispatch()

    useEffect(()=>{
        setRequestString(createRequestString(filterChoices))
    }, [filterChoices])

    const createRequestString = (filters)=>{
        let requestString = ''
        for (const key in filters) {
            if(filters[key].length < 1){
                continue
            }
            if(Array.isArray(filters[key])){
                let styleString = ''
                filters[key].forEach((item)=>{
                    if(item.name !=='any'){
                        styleString+=`${[key]}=${item.name}&`
                    }
                })
                requestString += `${styleString}`
                continue
            }
            let subString = '';
            
            if(filters[key].name !=='any'){
                subString = `${[key]}=${filters[key].name}`;
                requestString += `${subString}&`
            }
        }
        requestString = requestString.slice(0, requestString.length-1)
        return requestString
    }

    const getAdvertsData = () => {
        setFetching(true)
        dispatch(showLoaderAction())
        server.searchAdverts(requestString)
        .then((adverts)=>{
            setAdvertsData(adverts)
            dispatch(setAdvertsDataAction(adverts))
        })
        .then(()=>{
            setFetching(false)
        })
        .catch((e)=>{
            console.log(e)
        })
        .finally(()=>{
            dispatch(hideLoaderAction())
        })
    }

    return{
        getAdvertsData, advertsData, fetching
    }
}

export {useSearchForm}