import React, {useState, useEffect} from 'react';
import DataService from '../service/dataService';
import { useSelector, useDispatch } from 'react-redux';
import FiltersDataService from '../service/filtersDataService';
import { showAlertAction } from '../store/reducers/alertReducer';


const UserContext = React.createContext(null);

const UserProvider = ({children})=>{

    const userData = useSelector((state)=>{
        return state.user.currentUser.user
    })


    const isAuth = useSelector((state)=>{
        return state.user.isAuth
    })

    const filtersService = new FiltersDataService()
    const {searchAdvertTypes, newAdvertTypes} = filtersService
    const [fetching, setFetching] = useState(true)
    const [filterValues, setFilterValues] = useState({
        styles : [],
        cities: [],
        instruments : [],
        searchAdvertTypes,
        newAdvertTypes,
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        
        const server = new DataService();
        server.getFiltersData()
        .then((filtersData)=>{
            const {cities, instruments, styles} = filtersData
            setFilterValues(
                {...filterValues,
                styles,
                cities,
                instruments,
                }
            )
        })
        .then(()=>{
            setFetching(false)
        })
        .catch((err)=>{
            setFetching(false)
            dispatch(showAlertAction({
                type: 'error',
                text: 'не удалось получить данные для фильтров'
            }))
        })
    }, [])

    let contextData = {
        userData,
        isAuth,
        filterValues
        }

    if(!fetching){
        return(
            <UserContext.Provider value = {contextData}>
                {children}
            </UserContext.Provider>
        )
    }
}

export {UserContext, UserProvider} 