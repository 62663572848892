import config from "../config.json";

export default class DataService {
  baseUrl = config.BASE_URL;
  cities = "/Cities";
  styles = "/Styles";
  instruments = "/Instruments";
  adverts = "/Adverts";
  users = "/Users";

  api = {
    auth: "/api/auth/auth",
    checkAuth: "/api/auth/check_auth",
    registration: "/api/auth/registration",
    checkEmail: "/api/auth/check_email",
    login: "/api/auth/login",
    activateUser: "api/auth/activate_user",
    updateUserProfile: "api/profile/update",
    deleteUserProfile: "api/profile/delete",
    userAdverts: "api/profile/userAdverts",
    uploadAvatar: "api/profile/upload_avatar",
    deleteAvatar: "api/profile/delete_avatar",
    adverts: "api/adverts",
    newAdvert: "api/adverts/new",
    editAdvert: "api/adverts/edit",
    deleteAdvert: "api/adverts/delete",
    searchAdverts: "api/search/adverts",
    advert: "api/advert",
    filtersData: "api/search/filtersData",
    cnangeUserEmail: "api/profile/change_email",
    confirmPassword: "api/profile/confirm_password",
    changePassword: "api/profile/change_password",
    resetPassword: "api/auth/reset_password",
    requestPassword: "api/auth/request_password",
    vkLogin: "api/auth/vk/login",
    port: config.API_PORT,
  };

  //common functions

  getData = async (url) => {
    try {
      const answer = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (answer.ok) {
        return await answer.json();
      } else {
        const json = await answer.json();
        let error = new Error(json.message);
        throw error;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };

  postData = async (data, url) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      const json = await response.json();
      const message = json.message;
      const error = new Error(message);
      throw error;
    }
  };

  putData = async (data, url) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      const json = await response.json();
      const message = json.message;
      const error = new Error(message);
      throw error;
    }
  };

  deleteData = async (url) => {
    try {
      console.log(url);
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        return await response.json();
      } else {
        const json = await response.json();
        const message = json.message;
        const error = new Error(message);
        throw error;
      }
    } catch (error) {
      console.log(error);
    }
  };

  uploadFile = async (data, url) => {
    try {
      const res = await fetch(url, {
        method: "POST",

        body: data,
      });
      if (res.ok) {
        return res.json();
      } else {
        const json = res.json();
        const message = json.message;
        const error = new Error(message);
        throw error;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Users functions

  registration = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}${this.api.registration}`
    );
    return response;
  };

  login = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}${this.api.login}`
    );
    return response;
  };

  checkEmail = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}${this.api.checkEmail}`
    );
    return response;
  };

  auth = async () => {
    const response = await fetch(
      `${this.baseUrl}:${this.api.port}${this.api.auth}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      localStorage.removeItem("token");
      const json = await response.json();
      const message = json.message;
      const error = new Error(message);
      throw error;
    }
  };

  checkAuth = async () => {
    const response = await fetch(
      `${this.baseUrl}:${this.api.port}${this.api.checkAuth}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  };

  getActivateStatus = async (link) => {
    const response = await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.activateUser}/${link}`
    );
    return response;
  };

  updateUserProfile = async (data) => {
    const response = await this.putData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.updateUserProfile}`
    );
    return response;
  };

  changeUserEmail = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.cnangeUserEmail}`
    );
    return response;
  };

  confirmPassword = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.confirmPassword}`
    );
    return response;
  };

  changeUserPassword = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.changePassword}`
    );
    return response;
  };

  requestPassword = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.requestPassword}`
    );
    return response;
  };

  resetPassword = async (token) => {
    const response = await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.resetPassword}/${token}`
    );
    return response;
  };

  deleteUser = async (id) => {
    const response = await this.deleteData(
      `${this.baseUrl}:${this.api.port}/${this.api.deleteUserProfile}/${id}`
    );
    return response;
  };

  uploadAvatar = async (data) => {
    const response = await this.uploadFile(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.uploadAvatar}`
    );
    return response;
  };

  changeAvatar = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.changeAvatar}`
    );
    return response;
  };

  deleteAvatar = async (userId) => {
    const response = await this.deleteData(
      `${this.baseUrl}:${this.api.port}/${this.api.deleteAvatar}/${userId}`
    );
    return response;
  };

  //Adverts functions

  searchAdverts = async (requestString) => {
    return await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.searchAdverts}?${requestString}`
    );
  };

  getAdvertData = async (id) => {
    return await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.adverts}/${id}`
    );
  };

  postAdvertData = async (data) => {
    const response = await this.postData(
      data,
      `${this.baseUrl}:${this.api.port}/${this.api.newAdvert}`
    );
    return await response;
  };

  editAdvert = async (data, id) => {
    const response = await this.putData(
      data,
      ` ${this.baseUrl}:${this.api.port}/${this.api.editAdvert}/${id}`
    );
    return response;
  };

  deleteAdvert = async (id) => {
    const response = await this.deleteData(
      ` ${this.baseUrl}:${this.api.port}/${this.api.deleteAdvert}/${id}`
    );
    return response;
  };

  getFiltersData = async () => {
    const response = await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.filtersData}`
    );
    return response;
  };

  getUserAdverts = async (userId) => {
    const response = await this.getData(
      `${this.baseUrl}:${this.api.port}/${this.api.userAdverts}?id=${userId}`
    );
    return response;
  };

  vkLogin = async (payload) => {
    const response = await fetch(
      `${this.baseUrl}:${this.api.port}/${this.api.vkLogin}`,
      {
        body: payload,
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );
    return response;
  };
}
