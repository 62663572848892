import {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import DataService from '../service/dataService';
import { signInAction } from '../store/reducers/userReducer';

const CheckAuth = ({children})=>{

    const [authentification, setAuthentification] = useState(true)
    const server = new DataService()
    const dispatch = useDispatch()

    useEffect(()=>{
        server.checkAuth()
        .then((data)=>{
            if(data.isAuth){
                dispatch(signInAction(data.state))
            }
            setAuthentification(false)
        })
        .catch((err)=>{
            console.log(err.message)
        })
    }, [])

    if(!authentification){
        return children
    }
}

export {CheckAuth}