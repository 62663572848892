import React, {useContext, useState} from 'react';
import { UserContext } from '../../hoc/userContext';
import { Form , Button} from 'reactstrap';
import SingleValueSelect from '../../components/common/singleValueSelect';
import MultiValueSelect from '../../components/common/multiValueSelect/multiValueSelect';
import { useSearchForm } from '../../hooks/useSearchForm';
import {getDefaultValue} from '../../helpers/getDefaultValue';

const SearchForm = ()=>{

    const {filterValues} = useContext(UserContext)
    const {styles, cities, instruments, searchAdvertTypes} = filterValues

    const anyAdvertType = getDefaultValue(searchAdvertTypes)
    const anyInstrument = getDefaultValue(instruments)
    const anyCity = getDefaultValue(cities)
    const anyStyle = [getDefaultValue(styles)]
    
    const [instrument, setInstrument] = useState(anyInstrument)
    const [city, setCity] = useState(anyCity)
    const [style, setStyle] = useState(anyStyle)
    const [advertType, setAdvertType] = useState(anyAdvertType)

    const filterChoices = {
        instrument, city, style, advertType
    }

    console.log(filterChoices)

    const {getAdvertsData} = useSearchForm(filterChoices)

    

    return (
        <Form>
            <SingleValueSelect
            propName = 'advertType'
            inputClass = 'advert_type_input'
            label = 'Кого ищем?'
            containerClass = 'avert-type_container'
            dropDownData = {searchAdvertTypes}
            setValue = {setAdvertType}
            currentValue = {advertType}
            anyValue = {anyAdvertType}
            />
            <SingleValueSelect
            containerClass = 'instrument_container'
            propName = 'instrument'
            inputClass = 'instrument_input'
            label = 'Выберите инструмент'
            dropDownData = {instruments}
            setValue = {setInstrument}
            currentValue = {instrument}
            anyValue = {anyInstrument}
            />
            <SingleValueSelect
            dropDownData = {cities}
            propName = 'city'
            label = 'Выберите город'
            containerClass = 'city-container'
            setValue = {setCity}
            currentValue = {city}
            anyValue = {anyCity}
            />
            <MultiValueSelect
            dropDownData = {styles}
            label='Выберите предпочитаемый музыкальный стиль или жанр'
            propName = 'styles'
            inputClass = 'styles_input'
            setValue = {setStyle}
            anyValue = {anyStyle}
            
            />
            <Button
            onClick={(e)=>{
                e.preventDefault()
                getAdvertsData()
            }}
            className="center mt-4" > Поиск </Button>
        </Form>
    )
}
export {SearchForm}