import React, { useState, useContext } from 'react';
import { NavbarButton } from './navbarButton';
import { Navbar , NavbarBrand , NavbarToggler , Collapse , Nav , NavItem } from 'reactstrap';
import {NavLink, Link} from 'react-router-dom'
import {UserContext}  from '../../../hoc/userContext';
import { useDispatch } from 'react-redux';
import { logOutAction } from '../../../store/reducers/userReducer';


const Header = ()=>{

    const dispatch = useDispatch()

    const {isAuth} = useContext(UserContext);
    const [isShow, setShow] = useState('');
    const toggleMenu = (isShow)=>{
        if(isShow){
            setShow('')
        }
        else{
            setShow('show');
        }
    }

    let navigation;

    if(isAuth){
      navigation =
      <Nav
          className="me-auto"
          navbar
        >
          <NavItem>
            <NavLink className = "nav-link"  to="/search" onClick={()=>{setShow('')}}>
              Поиск
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className = "nav-link" to="/profile" onClick={()=>{setShow('')}}>
              Профиль
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className = "nav-link" to="/new_advert" onClick={()=>{setShow('')}}>
              Новое объявление
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className = "nav-link" to="/" onClick={()=>{setShow(''); 
          dispatch(logOutAction())
          localStorage.removeItem('token')
          }}>
              Выход
            </NavLink>
          </NavItem>
        </Nav>
    }
    else{
      navigation =
      <Nav
          className="me-auto"
          navbar
        >
          <NavItem>
            <NavLink className = "nav-link"  to="/search" onClick={()=>{setShow('')}}>
              Поиск
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className = "nav-link" to="/registration" onClick={()=>{setShow('')}}>
            Регистрация
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className = "nav-link" to="/login" onClick={()=>{setShow('')}}>
              Вход
            </NavLink>
          </NavItem>
        </Nav>
    }



    return(
        
  <Navbar
    className='header_navbar header'
    expand="md"
    container="sm"
  >
      <NavbarBrand tag="div" className='brand-link' >
        <Link to="/search" >
          Music City
        </Link>
      </NavbarBrand>
      <NavbarToggler  onClick={()=>{toggleMenu(isShow)}}>
          <NavbarButton isShow ={isShow}/>
      </NavbarToggler>
      <Collapse className={isShow} navbar>
        {navigation}
      </Collapse>
  </Navbar>
    )
}

export {Header}

