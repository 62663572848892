import {useState, useEffect} from 'react';
import DataService from '../service/dataService';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertAction } from '../store/reducers/alertReducer';
import { getDefaultValue } from '../helpers/getDefaultValue';
import { authSelector } from '../store/selectors';

const server = new DataService()

const useEditAdvertForm = (advertId, filterValues)=>{

    const {newAdvertTypes, styles, cities, instruments} = filterValues

    const [fetchingAdvertData, setFetchingAdvertData] = useState(true);
    const [advertData, setAdvertData] = useState({})
    const [redirectUrl, setRedirectUrl] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [advertDescription, setAdvertDescription] = useState('')
    const [contacts, setContacts] = useState([]);

    const anyAdvertValue = getDefaultValue(newAdvertTypes)
    const anyStyleValue = [getDefaultValue(styles)]
    const anyCityValue = getDefaultValue(cities)
    const anyInstrumentValue = getDefaultValue(instruments)

    const [instrument, setInstrument] = useState(anyInstrumentValue)
    const [city, setCity] = useState(anyCityValue)
    const [style, setStyle] = useState(anyStyleValue)
    const [advertType, setAdvertType] = useState(anyAdvertValue)

    const dispatch = useDispatch()

    useEffect(()=>{
        server.getAdvertData(advertId)
        .then((AdvertData)=>{
            setAdvertData(AdvertData)
            setAdvertType(AdvertData.advertType)
            setCity(AdvertData.city)
            setInstrument(AdvertData.instrument)
            setStyle(AdvertData.style)
            setAdvertDescription(AdvertData.advertDescription)
            setContacts(AdvertData.contacts)
        })
        .then(()=>{
            setFetchingAdvertData(false)
        })
        .catch((error)=>{
            dispatch(showAlertAction({
                type: 'error',
                text: error.message
            }))
        })
    }
    , [])

    const publishedDate = new Date()

    const changeAdvertDescription = (e)=>{
        setAdvertDescription(e.target.value) 
    }

    const addContacts = (contacts)=>{
        setContacts(contacts)
    }

    const saveAdvert = (data, id)=>{
        server.editAdvert(data, id)
        .then((response)=>{
            dispatch(showAlertAction({
                type: 'sucsess',
                text: response.message
            }))
            setRedirect(true)
            setRedirectUrl(`/adverts/${id}`);
        })
        .catch((error)=>{
            dispatch(showAlertAction({
                type: 'error',
                text: error.message
            }))
        })
    }

    const deleteAdvert = (id)=>{
        server.deleteAdvert(id)
        .then((response)=>{
            dispatch(showAlertAction({
                type: 'sucsess',
                text: response.message
            }))
            setRedirect(true)
            setRedirectUrl('/profile');
        })
        .catch((error)=>{
            dispatch(showAlertAction({
                type: 'error',
                text: error.message
            }))
        })
    }

    const currentValues = {
        instrument, 
        city, 
        advertType, 
        style
    }

    const anyValues = {
        anyAdvertValue,
        anyInstrumentValue,
        anyStyleValue,
        anyCityValue
    }

    const setValues = {
        setCity,
        setStyle,
        setAdvertType,
        setInstrument
    }

return {
    setValues, 
    anyValues,
    currentValues,
    redirect, 
    redirectUrl, 
    publishedDate, 
    advertDescription,
    contacts,
    fetchingAdvertData,
    changeAdvertDescription,
    addContacts,
    saveAdvert,
    deleteAdvert
    }

}

export {useEditAdvertForm}

