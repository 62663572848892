import React, {useContext} from 'react'
import { UserContext } from '../../hoc/userContext'
import { ProfileName } from '../../components/profileName'
import { ProfileEmail } from '../../components/profileEmail';
import { ProfileAvatar } from '../../components/profileAvatar';
import { ProfilePassword } from '../../components/profilePassword';
import { DeleteUser } from '../../components/deleteUser';

const UserInfo = ()=>{

    const {userData} = useContext(UserContext)
    console.log(userData)


    return(
        <>
            <h3 className="mt-4 mb-4 text-muted " > Инфромация о пользователе:</h3>
            <ProfileAvatar
            userId = {userData.id}
            />

            <ProfileName
            label = "Имя пользователя"
            userName = {userData.name}
            />
            <ProfileEmail
            label = "Email"
            userEmail = {userData.email}
            />
            <ProfilePassword
            />
            <DeleteUser
            userId = {userData.id}
            />

        </>
    )
}

export {UserInfo}