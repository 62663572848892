import React from "react";

import { RegistrationForm } from "../modules/RegistrationForm/RegistrationForm";

  const RegistrationPage = ()=>{
 

    return(
        <>
    <h1 className="text-center main-title mt-5"> Регистрация </h1>
    <div className="form_container">
      <RegistrationForm/>
    </div>
  </>
    )
}

export {RegistrationPage}