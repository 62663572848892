import { isEmail } from "../helpers/validators";
import { useEffect, useState } from "react";
import DataService from "../service/dataService";
import { useDispatch } from "react-redux";
import { showAlertAction } from "../store/reducers/alertReducer";

const useNewEmail = (userEmailValue)=>{

    const [notUniqeEmailError, setNotUniqueEmailError] = useState(false)
    const [notValidEmailError, setNotValidEmailError] = useState(false)

    useEffect(()=>{
        setNotValidEmailError(!isEmail(userEmailValue));
    }, [userEmailValue])

    const server = new DataService()
    const dispatch = useDispatch()

    const checkUniqueEmail = ()=>{
        if(!notValidEmailError && userEmailValue !== ""){
        server.checkEmail({email: userEmailValue})
        .then((res)=>{
          dispatch(showAlertAction({
            text: res.message,
            type: 'sucsess'
          }))
        })
        .catch((err)=>{
          setNotUniqueEmailError(true)
        })
        }
        else return
    }

    return{
        notUniqeEmailError, notValidEmailError, checkUniqueEmail, setNotUniqueEmailError
    }
}

export {useNewEmail}