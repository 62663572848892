import React from "react";
import {ListGroupItem} from 'reactstrap'

export default function MultiValueListItem (props){
    const {children,
      item,
      choiceList, 
      setValue, 
      setInputFocus, 
      setBackground,
      multiChoice
    } = props;


    const {_id, name, label} = item
    
    let dropdownItemClass = '';

    if (choiceList.length >0){
      choiceList.forEach((listItem)=>{
        if(listItem.id === _id){
            dropdownItemClass = 'list__item-active'
        }
      })
    }

    return(
        <ListGroupItem
            id = {_id}
            name = {name}
            label = {label}
            onClick = {() => {
            setInputFocus(false);
            setBackground(false)
            setValue(multiChoice(_id, name, label));
            }
          }
            className={`list_item  ${dropdownItemClass}`}>
                {children}
        </ListGroupItem>
    )
}