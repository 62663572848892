import React, {useState} from 'react'
import { AvatarEditWindow } from './avatarEditWindow'
import { useSelector } from 'react-redux'
import {currentUserSelector} from '../store/selectors.js'; 
import { Avatar } from './avatar';

const ProfileAvatar = ()=>{

    const userData = useSelector(currentUserSelector)
    const avatar = userData.user.avatar

    const [avatarData, setAvatarData] = useState(avatar)
    return (

    <div
    className='row mb-5'>
        <dt className=" profile_info_field col-md-1 col-sm-12">{'Аватар'}</dt>
        <dd className='col-md-5 col-sm-12 '>
            <Avatar
            width = "250"
            height = "250"
            src = {avatarData}
            />
            
        </dd>
        <div className='col-md-5 col-sm-12'>
            <AvatarEditWindow
            setAvatarData = {setAvatarData}
            userData = {userData.user}
            />
        </div>
    </div>
    )
}

export {ProfileAvatar}