import React, {useState, useContext} from 'react';
import { UserContext } from '../../hoc/userContext';
import { useFavoriteButton } from '../../hooks/useFavoriteButton';


const FavoriteButton = (props)=>{

    const {advertData, fontSize = '2em'} = props
    const {userData} = useContext(UserContext);
    const userId = userData.id
    const {updateAdvertsData, favoriteStatus} = useFavoriteButton(advertData, userId)
    const [favorite, setFavorite] = useState(favoriteStatus)

    console.log(advertData)
    const favoriteHandle = ()=>{
        setFavorite(!favorite)
        updateAdvertsData()
    }

    const buttonClass = favorite?
    'bi bi-star-fill'
    :
    'bi bi-star'

    return(
        <div
        onClick={favoriteHandle}
        >
            <i className={`${buttonClass} favoriteButton`} style = {{fontSize: `${fontSize}`}} ></i>
        </div>
    )
}

export {FavoriteButton}