import React from "react"
import { NewAdvertForm } from "../modules/NewAdvertForm/NewAdvertForm"

const NewAdvertPage = ()=>{
    return(
        <NewAdvertForm/>
    )
}


export {NewAdvertPage}