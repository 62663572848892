import React, {useState} from 'react';


const ModalWindow = (props)=>{
  const [modal, setModal] = useState(false);
  const {modalMessage, modalTitle, buttonText, confirm, id} = props

  const toggle = () => setModal(!modal);

  return (
    <>
      <button
      onClick={()=>{toggle()}}
      type="button" className="mt-5 btn btn-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        {buttonText}
      </button>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">{modalTitle}</h1>
              <button
              onClick = {()=>{
                toggle()
              }}
               type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {modalMessage}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> Отмена </button>
              <button
              data-bs-dismiss="modal"
              onClick = {()=>{
                confirm(id);
                setModal(false)
              }
              }
              type="button" className="btn btn-danger"> {buttonText} </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export {ModalWindow};